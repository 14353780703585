import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import Cookies from "universal-cookie";
import DatePicker from "react-datetime";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { addAllowance, deleteAllowance } from "./AllowanceAction";
import {
  branch_id,
  applyTo,
  maturityPeriod,
  payTypes,
  duration,
  maturityTime,
  payPer,
} from "../../utils";
// import RsDateRangePicker from "../RsDateRangePicker";

function AllowanceSettings(props) {
  const [settingsName, setSettingsName] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [durationType, setDurationType] = useState("");
  const [yearMonth, setYearMonth] = useState("");
  const [numberYearMonth, setNumberYearMonth] = useState("");
  const [calculatePer, setCalculatePer] = useState("");
  const [paymentPeriod, setPaymentPeriod] = useState("");
  const [btnText, setBtnText] = useState("Save Settings");
  const [deleteBtnText, setDeleteBtnText] = useState("Delete");
  const [newSettingsName, setNewSettingsName] = useState("");
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const history = useHistory();
  const [bonusSettings, setBonusSettings] = useState({
    percentage: 0,
    enabled: false,
    basic: false,
    housing: false,
    medical: false,
    transport: false,
    utility: false,
    entertainment: false,
    dressing: false,
    earned: false,
  });
  const [applySelected, setApplySelected] = useState("All");
  const [gradeEmployess, setGradeEmployess] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedgradeEmployess, setSelectedGradeEmployess] = useState([]);
  const [defaultgradeEmployess, setDefaultGradeEmployess] = useState([]);
  const [getAllSets, setGetAllSets] = useState([]);
  const [selectSettings, setSelectSettings] = useState([]);
  const [settingsDefault, setsettingsDefault] = useState([]);
  const [leaveId, setleaveId] = useState(null);
  const [customSettings, setCustomSettings] = useState([]);
  const [showDeduct, setShowDeduct] = useState({});

  const cookies = new Cookies();
  const token = "dummy token";

  useEffect(() => {
    const slug = window.location.pathname.split("/")[3];
    const name = slug.replaceAll("-", " ");
    setSettingsName(name);
    getSettings(slug);

    const unlisten = history.listen((location) => {
      if (!location.pathname.includes("allowance")) return;
      resetState();
      const slug = window.location.pathname.split("/")[3];
      const name = slug?.replaceAll("-", " ");
      setSettingsName(name);
      getSettings(slug);
    });

    return () => unlisten();
  }, [settingsName, history]);

  useEffect(() => {
    if (applySelected === "All") {
      setGradeEmployess([]);
      setSelectedGradeEmployess([]);
    } else if (applySelected === "Job Grade") {
      setIsFetching(true);
      fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const jg = [...result.jobGrades].map((d) => ({
            label: d.name,
            value: d._id,
          }));
          setGradeEmployess([]);
          setGradeEmployess(jg);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
        });
    } else if (applySelected === "Employees") {
      setIsFetching(true);
      fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const em = result.employees.map((d) => ({
            label: `${
              d?.personalInfo?.name?.first ? d?.personalInfo?.name?.first : ""
            } ${
              d?.personalInfo?.name?.middle ? d?.personalInfo?.name?.middle : ""
            } ${
              d?.personalInfo?.name?.last ? d?.personalInfo?.name?.last : ""
            }`,
            value: d.userId,
          }));
          setIsFetching(false);
          setGradeEmployess([]);
          setGradeEmployess(em);
        })
        .catch((err) => {
          setIsFetching(false);
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
        });
    } else if (applySelected === "Contract") {
      fetch(`${URLS.backendContracts}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result);
          const jg = [...result.contracts].map((d) => ({
            label: d.contractName,
            value: d._id,
          }));
          setGradeEmployess([]);
          setGradeEmployess(jg);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
        });
    }

    return () => {};
  }, [applySelected]);

  useEffect(() => {
    if (settingsName !== "") {
      fetch(`${URLS.backendApp}/set-up`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const { allowance, benefit, bonusSettings, deductionSettings } =
            result;
          let all = [
            ...allowance,
            ...benefit,
            ...bonusSettings,
            ...deductionSettings,
          ];

          all = all
            .filter((el) => el.name !== settingsName)
            .map((el) => ({
              label: el.name,
              value: { name: el.name, level: el.level, slug: el.slug },
            }));

          setGetAllSets(all);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
        });
    } else {
      fetchSalarySettings();
    }
  }, [settingsName]);

  const fetchSalarySettings = () => {
    fetch(`${URLS.backendsalarySetting}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((grades) => {
        const items = {};
        grades?.jobGrades?.length > 0 &&
          Object.entries(grades.jobGrades[0]).forEach((el) => {
            if (el[1] > 0) {
              items[`${el[0]}`] = true;
            }
          });
        setShowDeduct(items);
        const custom =
          grades?.jobGrades?.length > 0
            ? grades.jobGrades[0].custom.map((el) => ({
                name: el.name,
                status: false,
              }))
            : [];
        setCustomSettings(custom);
      });
  };

  const toggleModal = () => {
    window.$("#delete_employee").modal("toggle");
  };

  const resetState = () => {
    setSettingsName("");
    setBtnText("Save Settings");
    setDeleteBtnText("Delete");
    setNewSettingsName("");
    setBonusSettings({
      percentage: 0,
      basic: false,
      housing: false,
      medical: false,
      transport: false,
      utility: false,
      entertainment: false,
      dressing: false,
    });
    setSelectedGradeEmployess([]);
    setIsFetching(false);
    setGradeEmployess([]);
    setApplySelected("");
    setDefaultGradeEmployess([]);
    setGetAllSets([]);
    setsettingsDefault([]);
    setDurationType("");
    setYearMonth("");
    setNumberYearMonth(0);
  };

  const getSettings = async (slug) => {
    try {
      if (slug === "add-new") return;
      const token = "dummy token";
      const res = await fetch(`${URLS.backendAllowance}/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const data = await res.json();
      if (!res.ok) {
        throw Error(data);
      } else {
        setBonusSettings({
          ...data.allowance,
        });
        if (data?.allowance?.maturityPeriod) {
          const getTime = data?.allowance?.maturityPeriod?.split(" ");
          setYearMonth(getTime[1]);
          setNumberYearMonth(getTime[0]);
        }

        setleaveId(data?.allowance?.leaveId);
        data?.allowance?.durationType &&
          setDurationType(data?.allowance?.durationType);
        setPaymentType(data?.allowance?.paymentType);
        setPaymentPeriod(data?.allowance?.paymentPeriod);
        setCalculatePer(data?.allowance?.calculatePer);
        setDateRange({
          endDate: data?.allowance?.dateRange?.endDate,
          startDate: data?.allowance?.dateRange?.startDate,
        });
        if (data.allowance?.name) setSettingsName(data.allowance.name);
        if (data.allowance?.DeductType)
          setApplySelected(data.allowance?.DeductType);
        if (data.allowance?.selectedDeducts)
          setDefaultGradeEmployess(data.allowance?.selectedDeducts);
        data.allowance?.allSettings &&
          setsettingsDefault(data.allowance?.allSettings);

        if (data.allowance?.customSettings?.length > 0) {
          setCustomSettings(data.allowance?.customSettings);
        } else {
          fetchSalarySettings();
        }
      }
    } catch (err) {
      console.log(err);
      toast("Unable to get settings, please try again", {
        type: "error",
      });
    } finally {
    }
  };
  // console.log(dateRange);
  const deleteSetting = async (slug) => {
    toggleModal();
    try {
      setDeleteBtnText("Please wait...");
      const token = "dummy token";
      const res = await fetch(`${URLS.backendAllowance}/delete/${slug}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const data = await res.json();
      if (!res.ok) {
        throw Error(data);
      } else {
        toast("Settings deleted", {
          type: "success",
        });
        resetState();
        props.deleteAllowance(slug);
        props.history.push(`add-new`);
      }
    } catch (err) {
      console.log(err);
      toast("Unable to get settings, please try again", {
        type: "error",
      });
    } finally {
      setDeleteBtnText("Delete");
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const userId = cookies.get("userId");
      setBtnText("Please wait...");
      const token = "dummy token";

      const selected =
        selectedgradeEmployess.length > 0
          ? selectedgradeEmployess.map((d) => d.value)
          : defaultgradeEmployess;

      const allSettings =
        selectSettings.length > 0
          ? selectSettings.map((d) => d.value)
          : selectSettings.length === 0
          ? []
          : settingsDefault;

      let level;

      if (allSettings?.length < 1) {
        level = 1;
      } else {
        level = Math.max(...allSettings?.map((el) => el.level)) + 1;
      }

      // console.log(level);
      const thebonusSettings = {
        ...bonusSettings,
        // createdBy: props.User._id,
        createdBy: userId,
        DeductType: applySelected,
        selectedDeducts: selected,
        name: settingsName === "add new" ? newSettingsName : settingsName,
        branch_id,
        paymentType,
        paymentPeriod,
        dateRange,
        allSettings,
        level,
        maturityPeriod: `${numberYearMonth} ${yearMonth}`,
        durationType,
        calculatePer,
        customSettings,
      };

      // console.log(thebonusSettings);
      const res = await fetch(`${URLS.backendAllowance}/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          allowance: thebonusSettings,
          isNew: settingsName === "add new",
        }),
      });

      const data = await res.json();
      if (!res.ok) {
        throw Error(data.message);
      } else {
        // console.log(data);
        toast("Settings saved", {
          type: "success",
        });
        setBonusSettings({
          ...data.allowance,
        });
        props.addAllowance({
          ...data.allowance,
        });
        if (settingsName === "add new") {
          props.history.push(`${data.allowance.slug}`);
        }
      }
    } catch (err) {
      toast(err ? err.toString() : "Unable to submit, please try again", {
        type: "error",
      });
    } finally {
      setBtnText("Save Settings");
    }
  };

  const filterByDateRange = (event, name) => {
    setDateRange({
      ...dateRange,
      [name]: moment(event).format("MM/YYYY"),
    });
  };

  const validationEndDate = (currentDate) => {
    return currentDate.isAfter(
      moment(dateRange?.startDate, "MM/YYYY").add(0, "months")
    );
  };

  const changeCustom = (name) => {
    const all = customSettings.map((el, id) => {
      if (el.name === name) {
        return { ...el, status: !el?.status };
      } else {
        return el;
      }
    });
    setCustomSettings(all);
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Allowance Settings</BreadcrumbItem>
                  <BreadcrumbItem active>{settingsName}</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div class="p-3">
              <form onSubmit={(e) => submitForm(e)} autoComplete={false}>
                <h2 className="text-capitalize h3">
                  {settingsName === "add new" && "Create new allowance"}
                </h2>

                <div className="col-md-8 pl-0">
                  <div className="row">
                    {settingsName === "add new" && (
                      <div className="form-group col-md-6">
                        <label>Name</label>
                        <input
                          required
                          className="form-control"
                          type="text"
                          value={newSettingsName}
                          name="newSettingsName"
                          onChange={(e) => setNewSettingsName(e.target.value)}
                        />
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label>Show In Salary</label>
                          <div
                            className="onoffswitch ml-0"
                            onClick={(e) => {
                              setBonusSettings({
                                ...bonusSettings,
                                enabled: !bonusSettings.enabled,
                              });
                            }}
                          >
                            <input
                              type="checkbox"
                              name="onoffswitch"
                              className="onoffswitch-checkbox"
                              checked={bonusSettings.enabled}
                            />
                            <label className="onoffswitch-label">
                              <span className="onoffswitch-inner"></span>
                              <span className="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div>

                        {!leaveId && (
                          <div>
                            <label>Earned</label>
                            <div
                              className="onoffswitch ml-0"
                              onClick={(e) => {
                                setBonusSettings({
                                  ...bonusSettings,
                                  earned: !bonusSettings.earned,
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                name="onoffswitch"
                                className="onoffswitch-checkbox"
                                checked={bonusSettings.earned}
                              />
                              <label className="onoffswitch-label">
                                <span className="onoffswitch-inner"></span>
                                <span className="onoffswitch-switch"></span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-4">
                      <label>Calculate By</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                      >
                        {payTypes.map((a, i) => (
                          <option key={i} value={a.value}>
                            {a.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    {paymentType === "Percentage" ? (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Percentage</label>
                          <input
                            className="form-control"
                            type="text"
                            value={bonusSettings.percentage}
                            name="percentage"
                            onChange={(e) =>
                              setBonusSettings({
                                ...bonusSettings,
                                percentage: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : paymentType === "Static" ? (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Fixed </label>

                          <CurrencyInput
                            className="form-control"
                            value={bonusSettings.percentage}
                            allowNegativeValue={false}
                            // allowDecimals={false}
                            onValueChange={(value, name) =>
                              setBonusSettings({
                                ...bonusSettings,
                                percentage: value,
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {!leaveId && (
                      <div className="col-md-4">
                        <label>Calculate Per</label>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          value={calculatePer}
                          onChange={(e) => setCalculatePer(e.target.value)}
                        >
                          {payPer.map((a, i) => (
                            <option key={i} value={a.value}>
                              {a.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  {/*  */}

                  <div className="my-3">
                    <label>Apply allowance to :</label>
                    <div className="row">
                      <div className="col-6">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          value={applySelected}
                          onChange={(e) => setApplySelected(e.target.value)}
                        >
                          {applyTo.map((a, i) => (
                            <option key={i} value={a.value}>
                              {a.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      {gradeEmployess.length > 0 && (
                        <div className="col-6">
                          <Select
                            closeMenuOnSelect={true}
                            isLoading={isFetching}
                            isMulti
                            isSearchable={true}
                            defaultValue={
                              defaultgradeEmployess.length
                                ? gradeEmployess.filter((d) =>
                                    defaultgradeEmployess.find(
                                      (e) => e === d.value
                                    )
                                  )
                                : []
                            }
                            onChange={(selected) => {
                              setSelectedGradeEmployess(selected);
                            }}
                            options={gradeEmployess}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Maturity Period */}
                  <div className="row my-3">
                    <div className="col-md-6">
                      <label>Maturity Period (from joined date):</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={paymentPeriod}
                        onChange={(e) => setPaymentPeriod(e.target.value)}
                      >
                        {maturityPeriod.map((a, i) => (
                          <option key={i} value={a.value}>
                            {a.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    {paymentPeriod === "CustomPeriod" && (
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Period:</label>
                            <input
                              type="number"
                              placeholder="Number of months/years"
                              value={numberYearMonth}
                              className="form-control"
                              onChange={(e) =>
                                setNumberYearMonth(e.target.value)
                              }
                              required={
                                paymentPeriod === "CustomPeriod" ? true : false
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Month/Year:</label>
                            <select
                              className="form-control"
                              aria-label="Default select example"
                              value={yearMonth}
                              onChange={(e) => setYearMonth(e.target.value)}
                              required={
                                paymentPeriod === "CustomPeriod" ? true : false
                              }
                            >
                              {maturityTime.map((a, i) => (
                                <option key={i} value={a.value}>
                                  {a.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/*Duration Time  */}

                  <div className="row my-3">
                    <div className="col-md-6">
                      <label>Duration:</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={durationType}
                        onChange={(e) => setDurationType(e.target.value)}
                      >
                        {duration.map((a, i) => (
                          <option key={i} value={a.value}>
                            {a.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    {durationType === "CustomDuration" && (
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Start Date</label>

                            <div classname="datePicker">
                              <i
                                class="fa fa-calendar fa-xs calendar-icon"
                                aria-hidden="true"
                              ></i>
                              <DatePicker
                                value={dateRange.startDate}
                                name="startDate"
                                closeOnSelect={true}
                                dateFormat="MM/YYYY"
                                timeFormat={false}
                                onChange={(e) =>
                                  filterByDateRange(e, "startDate")
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label>End Date</label>

                            <div classname="datePicker">
                              <i
                                class="fa fa-calendar fa-xs calendar-icon"
                                aria-hidden="true"
                              ></i>
                              <DatePicker
                                value={dateRange.endDate}
                                name="endDate"
                                closeOnSelect={true}
                                dateFormat="MM/YYYY"
                                timeFormat={false}
                                onChange={(e) =>
                                  filterByDateRange(e, "endDate")
                                }
                                isValidDate={validationEndDate}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {paymentType === "Percentage" && (
                  <>
                    <hr />
                    <p>{bonusSettings.percentage}% of </p>
                    <div className="row">
                      {bonusSettings?.basic || showDeduct?.basic ? (
                        <div className="col-md-4">
                          <div
                            className="form-check"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings?.basic}
                              name="basic"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  basic: !bonusSettings?.basic,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Basic
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {bonusSettings?.housing || showDeduct?.housing ? (
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings?.housing}
                              name="housing"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  housing: !bonusSettings?.housing,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Housing
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {bonusSettings?.medical || showDeduct?.medical ? (
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings?.medical}
                              name="medical"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  medical: !bonusSettings?.medical,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Medical
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {bonusSettings?.transport || showDeduct?.transport ? (
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings?.transport}
                              name="transport"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  transport: !bonusSettings?.transport,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Transport
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {bonusSettings?.utility || showDeduct?.utility ? (
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.utility}
                              name="utility"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  utility: !bonusSettings.utility,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Utility
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {bonusSettings.entertainment ||
                      showDeduct?.entertainment ? (
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.entertainment}
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  entertainment: !bonusSettings.entertainment,
                                })
                              }
                              name="entertainment"
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Entertainment
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {bonusSettings?.dressing || showDeduct?.dressing ? (
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.dressing}
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  dressing: !bonusSettings.dressing,
                                })
                              }
                              name="dressing"
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Dressing
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {customSettings?.map((el) => (
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={el?.status}
                              onChange={() => changeCustom(el?.name)}
                              // name={el?.name}
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              {el?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>

                    {getAllSets.length > 0 && (
                      <div className="col-8 my-4">
                        <label>Add More:</label>
                        <Select
                          closeMenuOnSelect={true}
                          isLoading={isFetching}
                          isMulti
                          isSearchable={true}
                          defaultValue={
                            settingsDefault.length
                              ? getAllSets.filter((d) =>
                                  settingsDefault.find(
                                    (e) => e.name === d.label
                                  )
                                )
                              : []
                          }
                          onChange={(selected) => {
                            setSelectSettings(selected);
                          }}
                          options={getAllSets}
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="row">
                  <div className="col-md-2">
                    <button type="submit" className="btn btn-primary mt-3">
                      {btnText}
                    </button>
                  </div>

                  <div
                    className="col-md-2"
                    style={
                      ["add new", "pension", "payee tax", "nsitf"].includes(
                        settingsName
                      )
                        ? { display: "none" }
                        : {}
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-danger mt-3"
                      onClick={toggleModal}
                    >
                      {deleteBtnText}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}

        {/*<!-- Delete Setting Modal -->*/}
        <div
          className="modal custom-modal fade"
          id="delete_employee"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3 className="text-capitalize">
                    Delete {bonusSettings.name}
                  </h3>
                  <p>Are you sure want to delete this setting?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a
                        href="javascript:void(0);"
                        className="btn btn-primary continue-btn"
                        onClick={() => deleteSetting(bonusSettings.slug)}
                      >
                        Delete
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href="javascript:void(0);"
                        data-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<!-- /Delete Setting Modal -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAllowance: (slug) => dispatch(deleteAllowance(slug)),
    addAllowance: (allowance) => dispatch(addAllowance(allowance)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AllowanceSettings));
