import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import currency from "currency.js";
import { CSVLink } from "react-csv";
import {
  Dropdown,
  Overlay,
  Popover,
  OverlayTrigger,
  Button,
  Table,
  Form,
} from "react-bootstrap";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import { isEnterKey } from "../../utils";
import { SummaryChartIconUp } from "../icons";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { URLS } from "../urls";
import Prorated from "./Prorated";
import { toast } from "react-toastify";
import moment from "moment";
import { SummaryCloseIcon } from "../icons";
import { ConfirmDialog } from "../ConfirmDialogue";
import { allMonths, branch_id } from "../../utils";
import { DialogModal } from "../DialogModal";
import { InforModal } from "../InforModal";
import ImportPaylistFromExcel from "../editEmployeeJobGrade/ImportPaylistFromExcel";
import {
  totalUniqueNum,
  checkDateYear,
  calculateMultiPayRollSettings,
  getGrossPayments,
  calculateLoan,
  converArrayToObject,
} from "../../utils";
import ModalLoader from "../ModalLoader";
import { PDFIcon, ExcelIcon, ExportIcon } from "../icons";
import { useDownloadExcel } from "../useDownloadExcel";
import TableComponent from "../TableComponent";
import { parseInt, upperFirst } from "lodash";
import CurrencyInput from "react-currency-input-field";

const SalarySetting = ({ setting }) => {
  setting = {
    ...setting,
    ...converArrayToObject(setting["customSettings"], "status", "name"),
  };
  const salaryItems = useMemo(() => {
    let items = [];
    if (!setting) return items;
    for (const key in setting) {
      if (setting[key] === true && String(key) !== "enabled")
        items = [...items, upperFirst(key)];
    }
    items = [...items, ...setting?.allSettings?.map((el) => el.name)];
    return items;
  }, [setting]);

  const checkDates = () => {
    let data = null;
    let text = "Maturity period (from joined date)";
    if (setting?.paymentPeriod === "CustomDate") {
      const { endDate, startDate } = setting?.dateRange;
      text = "Maturity period from";

      data = `${startDate} - ${endDate}`;
    } else if (setting?.paymentPeriod === "All") {
      data = "Anytime";
    } else {
      data = setting?.paymentPeriod;
    }
    return { data, text };
  };

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={
        <Popover id={setting?.name}>
          <Popover.Title as="h3" className="text-capitalize">
            {setting?.name}
          </Popover.Title>
          <Popover.Content as={"div"}>
            <Table size="sm" borderless>
              <tr>
                <td className="font-weight-bold text-nowrap">Calculate by:</td>
                <td>{setting?.paymentType || "..."}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {setting?.paymentType === "Percentage"
                    ? `${setting?.percentage}% of ${salaryItems
                        .map((el) => el)
                        .join(", ")
                        .replace(/,(?!.*,)/gim, " and")}`
                    : setting?.percentage === "Static"
                    ? "Fixed"
                    : ` ${currency(setting?.percentage, {
                        symbol: "",
                      }).format()} flat rate`}
                </td>
              </tr>

              <tr>
                <td className="font-weight-bold">Apply to:</td>
                <td>{setting?.DeductType || "..."}</td>
              </tr>

              {setting?.paymentPeriod && (
                <tr>
                  <td className="font-weight-bold ">{checkDates().text}:</td>
                  <td className="text-nowrap">{checkDates().data || "..."}</td>
                </tr>
              )}
            </Table>
          </Popover.Content>
        </Popover>
      }
    >
      <Button
        className="d-inline p-0 border-0  px-2  rounded-circle  d-relative"
        size="sm"
        style={{ transform: `scale(0.7)` }}
      >
        <i className="fa fa-info"></i>
      </Button>
    </OverlayTrigger>
  );
};

function CreateSalary(props) {
  const date = new Date();
  const [activeTab, setActiveTab] = useState("1");
  const [normalEmployees, setNormalEmployees] = useState([]);
  const [proratedEmployees, setProratedEmployees] = useState([]);
  const [deductionSettings, setDeductionSettings] = useState([]);
  const [bonusSettings, setBonusSettings] = useState([]);
  const [allownaceSettings, setAllowanceSettings] = useState([]);
  const [benefitSettings, setBenefitSettings] = useState([]);
  const [overSettings, setOverSettings] = useState([]);
  const [leaveSettings, setLeaveSettings] = useState([]);
  const [pendingRetirement, setPendingRetirement] = useState([]);
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });
  // const [settings, setSettings] = useState({
  //   sendMail: false,
  // });

  const [totalGross, setTotalGross] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [totalDeduct, setTotalDeduct] = useState(0);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [dialog] = useState("send-payroll");
  const [openDialog, setOpenDialog] = useState(false);
  const [openInforDialog, setOpenInforDialog] = useState(false);
  const [useExcel, setUseExcel] = useState("");
  const [theexcelData, settheexcelData] = useState([]);
  const [btnText, setBtnText] = useState("Process Payroll");
  const [totalbonus, setTotalbonus] = useState(0);
  const [totalallowance, setTotalallowance] = useState(0);
  const [totalbenefit, setTotalbenefit] = useState(0);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [theLocations, settheLocations] = useState([]);
  const [theContracts, settheContracts] = useState([]);
  const [loanSettings, setloanSettings] = useState([]);
  const [companySettings, setcompanySettings] = useState([]);
  const [IsRuning, setIsRuning] = useState(false);
  const [IsFetching, setsFetching] = useState(true);
  const [customHeaders, setCustomHeaders] = useState(true);
  const [settingHeaders, setSettingsHeaders] = useState({});
  const [employeeType, setEmployeeType] = useState("All");

  // let isRendered = useRef(false);

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  const token = "dummy token";

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    IsRuning && getEmployees();

    return () => {};
  }, [useExcel, theexcelData, IsRuning, employeeType]);

  useEffect(() => {
    // Set Total Gross, Net and deductions
    setTotalGross(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.ogGrossPay), 0)
    );
    setTotalNet(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.netPay), 0)
    );
    setTotalDeduct(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.totalDeduction), 0)
    );
    setTotalbonus(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.totalBonus), 0)
    );
    setTotalallowance(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.totalAllowances), 0)
    );
    setTotalbenefit(() =>
      normalEmployees.reduce((p, c) => currency(p).add(c.totalBenefits), 0)
    );
  }, [normalEmployees]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await fetch(`${URLS.backendDeductionSettings}/enabled`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    const resBonus = await fetch(`${URLS.backendBonusSettings}/enabled`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    const resAllowance = await fetch(`${URLS.backendAllowance}/enabled`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    const resBenefit = await fetch(`${URLS.backendBenefit}/enabled`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    const resSetting = await fetch(
      `${URLS.backendCompanySettings}/get-settings`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const resloans = await fetch(`${URLS.backendLoan}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const reslocation = await fetch(`${URLS.backendLocations}/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const rescontract = await fetch(`${URLS.backendContracts}/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const overtime = await fetch(`${URLS.backendOvertime}/by-month`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const leaves = await fetch(
      `${URLS.backendApplyLeaves}/leave-by-month/${branch_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const resSalarySettings = await fetch(
      `${URLS.backendsalarySetting}/get/${branch_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      const retairement = await fetch(
        `${URLS.mainSiteBackend}/users/salary/pending-retirement?month=${payrollDate.payrollMonth}&year=${payrollDate.payrollYear}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const { retairment } = await retairement.json();
      setPendingRetirement(retairment);
    }

    const { locations } = await reslocation.json();
    const { contracts } = await rescontract.json();
    const { overtimes } = await overtime.json();
    const { leave } = await leaves.json();

    const { deductionSettings } = await res.json();
    const { settings } = await resSetting.json();
    const { bonusSettings } = await resBonus.json();
    const { loan } = await resloans.json();
    const { allowance } = await resAllowance.json();
    const { benefit } = await resBenefit.json();
    let salarySettings = await resSalarySettings.json();
    const items = {};
    Object.entries(salarySettings.jobGrades[0]).forEach((el) => {
      if (el[1] > 0) {
        items[`${el[0]}`] = true;
      }
    });

    salarySettings = salarySettings.jobGrades[0].custom.map((el) => el.name);
    setSettingsHeaders(items);
    setCustomHeaders(salarySettings);
    setLeaveSettings(leave);
    setAllowanceSettings(allowance);
    setBenefitSettings(benefit);
    setDeductionSettings(deductionSettings);
    setBonusSettings(bonusSettings);
    settheLocations(locations);
    settheContracts(contracts);
    setloanSettings(loan);
    setcompanySettings(settings);
    setOverSettings(overtimes);
    setIsRuning(true);
  };

  const getEmployees = async () => {
    try {
      // check if its getting from excel or database
      let mainEmployes;
      setsFetching(true);
      if (useExcel !== "Use Timesheet") {
        const employeeRes = await fetch(
          `${URLS.backendPayroll}/get-employees/${branch_id}?type=${employeeType}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        const employeesResult = await employeeRes.json();

        const { employees } = employeesResult;

        mainEmployes = employees;
      } else {
        mainEmployes = theexcelData;
      }
      // console.log(pendingRetirement);
      let normalEmployees = mainEmployes?.filter(
        (employee) =>
          employee?.jobInfo?.grade?.type === "normal" &&
          employee.hasResigned !== true
      );

      normalEmployees = normalEmployees?.map((employee) => {
        // console.log(employee?.jobInfo?.usergrade?.custom);
        const custom =
          employee?.jobInfo?.usergrade?.custom?.length > 0
            ? employee?.jobInfo?.usergrade?.custom
            : customHeaders?.map((el) => ({
                name: el,
                value: 0,
              }));

        employee = {
          ...employee.personalInfo,
          ...employee.jobInfo,
          // ...employee.jobInfo.grade.salary,
          ...converArrayToObject(
            employee?.jobInfo?.usergrade?.custom
              ? employee?.jobInfo?.usergrade?.custom
              : [],
            "value",
            "name",
            true
          ),
          custom,
          ...employee?.jobInfo?.usergrade?.salary,
          userId: employee.userId,
          bankDetails: employee.bankDetails ?? {},
          gradeName: employee.jobInfo.grade.name,
          emps_id: employee._id,
          totalDaysWorked: employee?.totalDaysWorked,
        };

        employee.usedlocation = theLocations?.find(
          (el) => el._id === employee?.workLocation
        )?.name;

        employee.usedlocation = employee.usedlocation
          ? employee.usedlocation
          : employee?.workLocation?.name;

        employee.usedcontract = theContracts?.find(
          (el) => el._id === employee?.contractType
        )?.contractName;

        const retaire = pendingRetirement?.find(
          (el) => el?.Staff_ID === employee?.employeeId
        );

        // console.log(allownaceSettings, "overSettings ==>", overSettings);
        const allNums = totalUniqueNum(
          benefitSettings,
          allownaceSettings,
          bonusSettings,
          deductionSettings
        );

        let allPREV = [];
        let benes = [];
        let allow = [];
        let bon = [];
        let deduct = [];

        for (const key of allNums) {
          const be = calculateMultiPayRollSettings(
            benefitSettings.filter((el) => el.level === key),
            employee,
            allPREV,
            companySettings,
            useExcel
          );

          benes = [...benes, ...be.otherData];
          allPREV = [...allPREV, ...be.otherData];

          const allo = calculateMultiPayRollSettings(
            allownaceSettings.filter((el) => el.level === key),
            employee,
            allPREV,
            companySettings,
            useExcel,
            overSettings,
            leaveSettings
          );

          allow = [...allow, ...allo.otherData];
          allPREV = [...allPREV, ...allo.otherData];

          const bons = calculateMultiPayRollSettings(
            bonusSettings.filter((el) => el.level === key),
            employee,
            allPREV,
            companySettings,
            useExcel
          );

          bon = [...bon, ...bons.otherData];
          allPREV = [...allPREV, ...bons.otherData];

          const dedt = calculateMultiPayRollSettings(
            deductionSettings.filter((el) => el.level === key),
            employee,
            allPREV,
            companySettings,
            useExcel
          );

          deduct = [...deduct, ...dedt.otherData];
          allPREV = [...allPREV, ...dedt.otherData];
        }

        // console.log(allPREV);

        const benefits = {
          totalSum: benes.reduce((c, p) => c + p.total, 0),
          otherData: benes,
        };

        const allowances = {
          totalSum: allow.reduce((c, p) => c + p.total, 0),
          otherData: allow,
        };

        const bonus = {
          totalSum: bon.reduce((c, p) => c + p.total, 0),
          otherData: bon,
        };

        let penCom = deduct.find((el) => el.slug === "company-pension");
        let penTotal = deduct.find((el) => el.slug === "pension");

        penCom = penCom ? penCom?.total : 0;
        penTotal = penTotal ? penTotal?.total : 0;
        penTotal = penCom + penTotal;

        const deductions = {
          totalSum: deduct.reduce((c, p) => c + p.total, 0) - penCom,
          otherData: deduct,
        };

        // Calculate loan
        const employeeLoan = loanSettings.filter(
          (el) =>
            el?.userID?._id === employee?.emps_id && el?.Term !== undefined
        );

        let empLoan = {};

        if (
          employeeLoan[0]?.payments?.length > 0 &&
          employeeLoan[0]?.loanmotion !== "Pause" &&
          employeeLoan[0]?.Term === "Annual" &&
          checkDateYear(employeeLoan[0]?.paidDate)
        ) {
          empLoan = calculateLoan(employeeLoan, employee);
        } else if (
          employeeLoan[0]?.payments?.length > 0 &&
          employeeLoan[0]?.loanmotion !== "Pause" &&
          employeeLoan[0]?.Term !== "Annual"
        ) {
          empLoan = calculateLoan(employeeLoan, employee);
        }

        // Get the deducted loan
        const loanDeduct = parseFloat(
          empLoan?.deductAmount ? empLoan?.deductAmount : 0
        );

        const data = getGrossPayments(employee, companySettings, useExcel);

        // Calculate all deductions
        const allTotalDeduction =
          parseFloat(deductions.totalSum) + parseFloat(loanDeduct);

        // Calculate all income
        const income =
          parseFloat(bonus.totalSum) +
          parseFloat(allowances.totalSum) +
          parseFloat(benefits.totalSum) +
          parseFloat(data.ogGrossPay);

        // parseFloat(retairefee);

        // Calculate Net pay
        let netPay = parseFloat(income) - parseFloat(allTotalDeduction);

        // Calculate retairment
        let remainingRetairment = 0;
        let retairefee = 0;
        if (retaire && retaire?.Amount > 0) {
          retairefee = retaire.Amount;
          if (parseFloat(netPay) > parseFloat(retairefee)) {
            remainingRetairment = parseFloat(retairefee);
          } else {
            remainingRetairment = parseFloat(netPay);
          }

          netPay = parseFloat(netPay) - parseFloat(remainingRetairment);
        }

        // console.log(data.employee, data.ogGrossPay);
        return {
          ...data.employee,
          loanDeduct,
          totalDaysWorked: employee?.totalDaysWorked,
          empLoan,
          ogGrossPay: data.ogGrossPay,
          grossPay: data.grossPay,
          otherDeductions: 0,
          bonuses: 0,
          totalDeduction: deductions.totalSum,
          ogNetPay: netPay,
          netPay,
          deductions: deductions.otherData,
          totalBonus: bonus.totalSum,
          totalAllowances: allowances.totalSum,
          totalBenefits: benefits.totalSum,
          bonus: bonus.otherData,
          allowances: allowances.otherData,
          benefits: benefits.otherData,
          debitAccountNo: "--",
          debitBankCode: "--",
          creditAccountNo: employee.bankDetails?.accountNumber,
          creditBankCode: employee.bankDetails?.bankCode,
          allTotalDeduction:
            parseFloat(allTotalDeduction) + parseFloat(retairefee),
          numProration:
            parseFloat(employee?.totalDaysWorked) /
            parseFloat(companySettings?.workingDays),
          useExcel,
          penTotal,
          retairefee,
          remainingRetairment,
          retaire,
          customHeaders,
          employeeType,
        };
      });

      setsFetching(false);
      setNormalEmployees(normalEmployees);
    } catch (err) {
      console.log(err);
      setsFetching(false);
    }
  };

  const calculateData = ({ e, employeeId }) => {
    if (isEnterKey(e)) {
      //  const newValue = e.target.value ? parseFloat(e.target.value) : 0;
      const index = normalEmployees.findIndex(
        (employee) => employee.employeeId === employeeId
      );
      const employee = normalEmployees[index];
      // const { ogGrossPay, bonuses, otherDeductions } = employee;
      const { netPay, otherDeductions, allTotalDeduction } = employee;
      console.log(otherDeductions);
      // let deductionSum = 0;
      // const calculatedDeductions = deductionSettings.map((deduction) => {
      //   let sum = 0;
      //   for (let key in deduction) {
      //     if (key !== "enabled" && deduction[key] === true) {
      //       sum += employee[key];
      //     }
      //   }
      //   const total = ((deduction.percentage / 100) * sum).toFixed(2);
      //   deductionSum += parseFloat(total);

      //   return {
      //     ...deduction,
      //     total: parseFloat(total),
      //   };
      // });

      // const grossPay = ogGrossPay + bonuses;
      normalEmployees[index] = {
        ...employee,
        netPay: parseFloat(netPay) - parseFloat(otherDeductions),
        allTotalDeduction:
          parseFloat(allTotalDeduction) + parseFloat(otherDeductions),
        // grossPay,
        // deductions: calculatedDeductions,
        // otherDeductions,
        // totalDeduction: deductionSum + otherDeductions,
        // netPay: grossPay - (deductionSum + otherDeductions),
      };

      setNormalEmployees([...normalEmployees]);
      // e.target.blur();
    }
  };

  const setOtherDeductions = ({ e, employeeId }) => {
    // const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    // console.log(e);
    const newValue = isNaN(parseInt(e)) ? 0 : parseInt(e);

    const index = normalEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = normalEmployees[index];

    normalEmployees[index] = {
      ...employee,
      otherDeductions: newValue,
    };

    setNormalEmployees([...normalEmployees]);
  };

  // const setBonuses = ({ e, employeeId }) => {
  //   const newValue = e.target.value ? parseFloat(e.target.value) : 0;
  //   const index = normalEmployees.findIndex(
  //     (employee) => employee.employeeId === employeeId
  //   );
  //   const employee = normalEmployees[index];

  //   normalEmployees[index] = {
  //     ...employee,
  //     bonuses: newValue,
  //   };

  //   setNormalEmployees([...normalEmployees]);
  // };

  const setRemark = ({ e, employeeId }) => {
    const index = normalEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = normalEmployees[index];

    normalEmployees[index] = {
      ...employee,
      remark: e.target.value,
    };
    setNormalEmployees([...normalEmployees]);
  };

  const savePayroll = () => {
    setBtnText("Please wait...");
    setIsSendLoading(true);
    let formData = new FormData();

    let pE = proratedEmployees.map((employee) => {
      return {
        ...employee,
        payrollMonth: payrollDate.payrollMonth,
        payrollYear: payrollDate.payrollYear,
        debitAccountNo: props.settings.debitAccountNumber,
        debitBankCode: props.settings.debitBankCode,
        type: "prorated",
        settingHeaders,
      };
    });
    let nE = normalEmployees.map((employee) => {
      return {
        ...employee,
        payrollMonth: payrollDate.payrollMonth,
        payrollYear: payrollDate.payrollYear,
        debitAccountNo: props.settings.debitAccountNumber,
        debitBankCode: props.settings.debitBankCode,
        type: "normal",
        settingHeaders,
      };
    });
    setOpenDialog(false);
    formData.append(
      "payload",
      JSON.stringify({
        payroll: [...pE, ...nE],
        payrollMonth: payrollDate.payrollMonth,
        payrollYear: payrollDate.payrollYear,
        companySettings,
        branch_id,
        employeeType,
      })
    );
    fetch(`${URLS.backendPayroll}/save-payroll-by-branch`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setIsSendLoading(false);
        if (result.success) {
          toast("payroll created", {
            type: "success",
            position: "top-right",
          });
          setOpenInforDialog(true);
        } else {
          toast(result.message, {
            type: "error",
            position: "top-right",
          });
          // throw Error();
        }
      })
      .catch((err) => {
        toast("payroll deleted", {
          type: "error",
        });
      })
      .finally(() => {
        setBtnText("Process");
      });
  };

  const onDownloadExcelData = async () => {
    let exData = normalEmployees.map((employee, index) => [
      index + 1,
      employee.name.first + " " + employee.name.last,
      employee.employeeId,
      useExcel === "Use Timesheet" ? employee?.sfa_id : "",
      employee.gradeName,
      employee.usedlocation,
      employee.usedcontract,
      useExcel === "Use Timesheet" ? employee?.totalDaysWorked : "",
      useExcel === "Use Timesheet"
        ? parseFloat(employee?.numProration).toFixed(2)
        : "",
      currency(employee.grossPay, {
        symbol: "",
      }).format(),
      currency(employee.basic, {
        symbol: "",
      }).format(),
      currency(employee.housing, {
        symbol: "",
      }).format(),
      currency(employee.transport, {
        symbol: "",
      }).format(),
      currency(employee.medical, {
        symbol: "",
      }).format(),
      currency(employee.utility, {
        symbol: "",
      }).format(),
      currency(employee.entertainment, {
        symbol: "",
      }).format(),
      currency(employee.dressing, {
        symbol: "",
      }).format(),
      ...employee?.custom?.map((el) =>
        currency(el?.value, {
          symbol: "",
        }).format()
      ),
      currency(employee.ogGrossPay, {
        symbol: "",
      }).format(),
      ...deductionSettings.map((e, i) => {
        return currency(
          employee.deductions.find((el) => el.slug === e.slug)?.total,
          {
            symbol: "",
          }
        ).format();
      }),
      ...bonusSettings.map((e, i) => {
        return currency(
          employee.bonus.find((el) => el.slug === e.slug)?.total,
          {
            symbol: "",
          }
        ).format();
      }),
      ...allownaceSettings.map((e, i) => {
        return currency(
          employee.allowances.find((el) => el.slug === e.slug)?.total,
          {
            symbol: "",
          }
        ).format();
      }),
      ...benefitSettings.map((e, i) => {
        return currency(
          employee.benefits.find((el) => el.slug === e.slug)?.total,
          {
            symbol: "",
          }
        ).format();
      }),
      employee.loanDeduct
        ? currency(employee.loanDeduct, {
            symbol: "",
          }).format()
        : 0,
      pendingRetirement.length > 0 && employee.remainingRetairment
        ? currency(employee.remainingRetairment, {
            symbol: "",
          }).format()
        : 0,
      currency(employee.allTotalDeduction, {
        symbol: "",
      }).format(),
      currency(employee.totalBonus, {
        symbol: "",
      }).format(),
      currency(employee.totalAllowances, {
        symbol: "",
      }).format(),
      currency(employee.totalBenefits, {
        symbol: "",
      }).format(),
      currency(employee.netPay, {
        symbol: "",
      }).format(),
    ]);

    const normalHead = [
      "No",
      "Name",
      "Employees ID",
      "SFA ID",
      "Job Grade",
      "Location",
      "LSP",
      "Days Worked",
      "Proration",
      "Gross Salary",
      "Basic",
      "Housing",
      "Transport",
      "Medical",
      "Utility",
      "Entertainment",
      "Dressing",
      ...customHeaders.map((el) => el),
      "Earned Gross Salary",
      ...deductionSettings.map((setting, index) => {
        return setting.name;
      }),
      ...bonusSettings.map((setting, index) => {
        return setting.name;
      }),
      ...allownaceSettings.map((setting, index) => {
        return setting.name;
      }),
      ...benefitSettings.map((setting, index) => {
        return setting.name;
      }),
      "Loan Deductions",
      "IOU Deductions",
      "Total Deductions",
      "Total Bonus",
      "Total Allowance",
      "Total Benefit",
      "Net Pay",
    ];

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    exData = [
      // [company],
      ["Payroll Report"],
      [date],
      [""],
      normalHead,
      ...exData,
      [""],
      ["Payroll Summary"],
      [
        "Total Earned Gross",
        currency(totalGross, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Allowance",
        currency(totalallowance, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Benefit",
        currency(totalbenefit, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Bouns",
        currency(totalbonus, {
          symbol: "",
        }).format(),
      ],

      [
        "Total Deduction",
        currency(totalDeduct, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Net Amount",
        currency(totalNet, {
          symbol: "",
        }).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th>No</th>
          <th>Name</th>
          <th>Employees ID</th>
          {useExcel === "Use Timesheet" && <th>SFA ID</th>}
          <th>Job Grade</th>
          <th>Location</th>
          <th>LSP</th>
          {useExcel === "Use Timesheet" && (
            <>
              <th>Days Worked</th>
              <th>Proration</th>
            </>
          )}
          <th>Gross Salary</th>
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.basic ? (
            <th>Basic</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.housing ? (
            <th>Housing</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.transport ? (
            <th>Transport</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.medical ? (
            <th>Medical</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.utility ? (
            <th>Utility</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.entertainment ? (
            <th>Entertainment</th>
          ) : null}
          {Object.entries(settingHeaders).length === 0 ||
          settingHeaders?.dressing ? (
            <th>Dressing</th>
          ) : null}
          {customHeaders.map((el) => (
            <th>{el}</th>
          ))}
          <th>Earned Gross Salary</th>
          {deductionSettings.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting.name} <SalarySetting setting={setting} />
                <br />
              </th>
            );
          })}
          {bonusSettings.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting.name} <SalarySetting setting={setting} />
                <br />
              </th>
            );
          })}

          {allownaceSettings.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting.name} <SalarySetting setting={setting} />
                <br />{" "}
              </th>
            );
          })}

          {benefitSettings.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting.name} <SalarySetting setting={setting} />
                <br />{" "}
              </th>
            );
          })}

          {/* <th>Other Deductions</th> */}
          <th>Loan Deductions</th>
          {pendingRetirement?.length > 0 && <th>IOU Deductions</th>}
          <th>Total Deductions</th>
          <th>Total Bonus</th>
          <th>Total Allowance</th>
          <th>Total Benefit</th>
          <th>Remark</th>
          <th>Net Pay</th>
        </tr>
      </thead>
    );
  };

  const tableBodyData = (employee, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>{employee.name.first + " " + employee.name.last}</td>
        <td>{employee.employeeId}</td>
        {useExcel === "Use Timesheet" && <td>{employee.sfa_id}</td>}
        <td>{employee.gradeName}</td>
        <td>{employee.usedlocation}</td>
        <td>{employee.usedcontract}</td>
        {useExcel === "Use Timesheet" && (
          <>
            <td>{employee?.totalDaysWorked}</td>
            <td>{parseFloat(employee?.numProration).toFixed(2)}</td>
          </>
        )}
        <td>
          {currency(employee.grossPay, {
            symbol: "",
          }).format()}
        </td>
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.basic ? (
          <td>
            {currency(employee.basic, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.housing ? (
          <td>
            {currency(employee.housing, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.transport ? (
          <td>
            {currency(employee.transport, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.medical ? (
          <td>
            {currency(employee.medical, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.utility ? (
          <td>
            {currency(employee.utility, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.entertainment ? (
          <td>
            {currency(employee.entertainment, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(settingHeaders).length === 0 ||
        settingHeaders?.dressing ? (
          <td>
            {currency(employee.dressing, {
              symbol: "",
            }).format()}
          </td>
        ) : null}

        {employee?.custom?.map((el) => (
          <td>
            {currency(el?.value, {
              symbol: "",
            }).format()}
          </td>
        ))}

        {/* <td>
            <input
              value={employee.bonuses}
              onChange={(e) => {
                setBonuses({
                  e,
                  employeeId: employee.employeeId,
                });
              }}
              onKeyUp={(e) => {
                calculateData({
                  e,
                  employeeId: employee.employeeId,
                });
              }}
              className="form-control payroll-input"
            />
          </td> */}
        <td>
          {currency(employee.ogGrossPay, {
            symbol: "",
          }).format()}
        </td>

        {deductionSettings.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.deductions.find((el) => el.slug === e.slug)?.total,
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {bonusSettings.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.bonus.find((el) => el.slug === e.slug)?.total,
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {allownaceSettings.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.allowances.find((el) => el.slug === e.slug)?.total,
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {benefitSettings.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.benefits.find((el) => el.slug === e.slug)?.total,
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {/* <td>
          <input
            value={employee.otherDeductions}
            onChange={(e) => {
              setOtherDeductions({
                e,
                employeeId: employee.employeeId,
              });
            }}
            onKeyUp={(e) => {
              calculateData({
                e,
                employeeId: employee.employeeId,
              });
            }}
            className="form-control payroll-input"
          />
          <CurrencyInput
            className="form-control"
            value={employee.otherDeductions}
            allowNegativeValue={false}
            // allowDecimals={false}
            // onValueChange={(value, name) => this.updateAmount(value)}
            onValueChange={(value) => {
              setOtherDeductions({
                e: value,
                employeeId: employee.employeeId,
              });
            }}
            onKeyUp={(value) => {
              calculateData({
                e: value,
                employeeId: employee.employeeId,
              });
            }}
          />
        </td> */}
        <td>
          {employee.loanDeduct
            ? currency(employee.loanDeduct, {
                symbol: "",
              }).format()
            : 0}
        </td>
        {pendingRetirement?.length > 0 && (
          <td>
            {employee.remainingRetairment
              ? currency(employee.remainingRetairment, {
                  symbol: "",
                }).format()
              : 0}
          </td>
        )}
        <td>
          {currency(employee.allTotalDeduction, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalBonus, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalAllowances, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalBenefits, {
            symbol: "",
          }).format()}
        </td>
        <td>
          <input
            value={employee.remark ?? "..."}
            onClick={(e) => e.target.select()}
            onChange={(e) => {
              setRemark({
                e,
                employeeId: employee.employeeId,
              });
            }}
            className="form-control payroll-input"
          />
        </td>
        <td>
          {currency(employee.netPay, {
            symbol: "",
          }).format()}
        </td>
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Process Payroll</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="mt-3">
              <div className="d-flex justify-content-between my-4">
                <div className="d-flex">
                  <div className="form-inline">
                    <label>Month</label>
                    <select
                      className="form-control mx-2"
                      value={payrollDate.payrollMonth}
                      onChange={(e) =>
                        setPayrollDate({
                          payrollMonth: e.target.value,
                          payrollYear: payrollDate.payrollYear,
                        })
                      }
                    >
                      {[...Array(12)].map((e, index) => (
                        <option key={index}>{index + 1}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-inline ml-3">
                    <label>Year</label>
                    <input
                      type="number"
                      style={{ width: "100px" }}
                      className="form-control mx-2"
                      value={payrollDate.payrollYear}
                      onChange={(e) =>
                        setPayrollDate({
                          payrollMonth: payrollDate.payrollMonth,
                          payrollYear: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div className="mx-3">
                    <Form.Control
                      placeholder="Search Employee"
                      value={searchText}
                      onChange={(e) => setsearchText(e.target.value)}
                    />
                  </div>

                  <div className="d-flex">
                    <select
                      className="form-control mx-2"
                      aria-label="Default select example"
                      value={employeeType}
                      onChange={(e) => setEmployeeType(e.target.value)}
                    >
                      <option value="All">All</option>
                      <option value="staff">Staff</option>
                      <option value="contract">Contract</option>
                    </select>

                    <select
                      className="form-control"
                      aria-label="Default select example"
                      value={useExcel}
                      onChange={(e) => setUseExcel(e.target.value)}
                    >
                      {["Use database", "Use Timesheet"].map((a, i) => (
                        <option key={i} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>

                    <div>
                      {useExcel === "Use Timesheet" && (
                        <Dropdown>
                          <Dropdown.Toggle variant="" bsPrefix="">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="text-center"
                          >
                            <Dropdown.Item as="div" className="p-cursor">
                              <ImportPaylistFromExcel
                                payrollMonth={payrollDate.payrollMonth}
                                payrollYear={payrollDate.payrollYear}
                                handleEmployeesToPay={settheexcelData}
                              />
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </div>

                  <div className="mx-2">
                    <CSVLink
                      className="btn print d-none"
                      filename={`Payroll Report(${moment(new Date()).format(
                        "DD-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        <span className="mx-2">Export</span>
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <button
                    onClick={() => setBtnSummaryActive(!btnSummryActive)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Salary Sheet
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Prorated
                  </NavLink>
                </NavItem> */}
              </Nav>
              {/* {normalEmployees.length > 0 ? ( */}
              {IsFetching ? (
                <ModalLoader show={IsFetching} />
              ) : (
                <>
                  {normalEmployees.length > 0 ? (
                    <TabContent activeTab={activeTab} className="pt-0">
                      <TabPane tabId="1">
                        <Row className="table-height">
                          <TableComponent
                            responsive
                            striped
                            sticky
                            tableHeadsFunction={tableHead}
                            mainDataArray={
                              searchText === ""
                                ? normalEmployees
                                : normalEmployees.filter(
                                    (el) =>
                                      el?.name?.first
                                        ?.toLowerCase()
                                        .includes(searchText.toLowerCase()) ||
                                      el?.name?.last
                                        ?.toLowerCase()
                                        .includes(searchText.toLowerCase())
                                  )
                            }
                            tableDataRowFunction={tableBodyData}
                            className="product-table text-nowrap"
                          />
                        </Row>
                      </TabPane>

                      {/* <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <Prorated setProratedEmployees={setProratedEmployees} />
                      </Col>
                    </Row>
                  </TabPane> */}
                    </TabContent>
                  ) : (
                    <div
                      className="w-100 d-flex justify-content-center align-items-center bg-white"
                      style={{ height: "250px" }}
                    >
                      <p>{`No data on ${
                        allMonths[payrollDate.payrollMonth - 1]
                      } ${payrollDate.payrollYear}`}</p>
                    </div>
                  )}
                </>
              )}

              <div className="mt-2">
                {/* <div className="my-3">
                  <label>Send Payslip Mail</label>
                  <div
                    className="onoffswitch ml-0"
                    onClick={(e) => {
                      setSettings({
                        ...settings,
                        sendMail: !settings.sendMail,
                      });
                    }}
                  >
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      checked={settings.sendMail}
                    />
                    <label className="onoffswitch-label">
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div> */}
                <button
                  class="btn btn-primary my-3"
                  data-toggle="modal"
                  data-target={`#${dialog}`}
                  onClick={() => setOpenDialog(true)}
                >
                  {btnText}
                </button>
              </div>

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="row justify-content-between mt-4">
                  <div className="col">
                    <div className="my-3">
                      <div className="d-flex gap-3 align-items-center mb-4">
                        <h4 className="m-0">Payroll Summary</h4>{" "}
                        <hr className="flex-grow-1 m-0" />
                        {btnSummryActive && (
                          <button
                            onClick={() =>
                              setBtnSummaryActive(!btnSummryActive)
                            }
                            className="btn p-0"
                          >
                            <SummaryCloseIcon />
                          </button>
                        )}
                      </div>

                      <div
                        className="gridCont mb-3 three-col-grid"
                        style={{ gridTemplateRows: "unset" }}
                      >
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftBlue">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalGross, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Earned Gross</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>

                        {/*  */}

                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalallowance, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Allowance</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalbenefit, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Benefit</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                      </div>

                      {/* Secound Order */}

                      <div
                        className="gridCont mb-3 three-col-grid"
                        style={{ gridTemplateRows: "unset" }}
                      >
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalbonus, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Bouns</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftOrange">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalDeduct, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Deduction</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalNet, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Net Amount</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
                <DialogModal
                  show={openDialog}
                  proceed={savePayroll}
                  handleClose={() => setOpenDialog(false)}
                  title="Process Payroll"
                  description={`Are you sure you want to process payroll for ${
                    allMonths[payrollDate.payrollMonth - 1]
                  } ${payrollDate.payrollYear}`}
                  sendTitle="Proceed"
                />
                <InforModal
                  show={openInforDialog}
                  proceed={savePayroll}
                  handleClose={() => setOpenInforDialog(false)}
                  description={`${allMonths[payrollDate.payrollMonth - 1]} ${
                    payrollDate.payrollYear
                  } process successfully`}
                  sendTitle="Salary Schedule"
                  link="/dashboard/salary-summary"
                  secondLink="/dashboard/pay-history"
                  secondTitle="Payroll History"
                />
              </div>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      <ModalLoader show={isSendLoading} />
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    settings: state.settingsReducer.settings,
    // deductionSettings: state.deductionSettings.deductions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateSalary));
