import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import UserTopbar from "../user-topbar/UserTopbar";
import UserSidebar from "../user-sidebar/UserSidebar";
import { Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { URLS } from "../../urls";
import DatePicker from "react-datetime";
import { toast } from "react-toastify";
import ModalLoader from "../../ModalLoader";
import moment from "moment";
import Cookies from "universal-cookie";
import { ApplyUserAllowanceModal } from "./ApplyUserAllowance";
import currency from "currency.js";
import SendForApprovalModal from "../../salary-settings/SendForApproval";

function UserAllowance({ allowance }) {
  const [status, setStatus] = useState("");
  const [showAddOvertimeModal, setShowAddOvertimeModal] = useState(false);
  const [overtimes, setOvertimes] = useState([]);
  const [overtimeIdToDelete, setOvertimeIdToDelete] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [showForApproval, setShowForApproval] = useState(false);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    status: "",
  });

  const [statsOvertime, setStatsOvertime] = useState({
    employee: 0,
    success: 0,
    pending: 0,
    reject: 0,
    sent: 0,
    paid: 0,
  });

  const getOvertimes = () => {
    setLoading(true);
    const { startDate, endDate, status } = filter;
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      const cookies = new Cookies();
      const id = cookies.get("userId");
      fetch(
        `${URLS.backendOvertime}/user/${id}?startDate=${startDate}&endDate=${endDate}&status=${status}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);
          setOvertimes([...result.overtimes]);
          resolve();
        })
        .catch((err) => {
          setLoading(false);
          toast("Unable to get, please refresh your browser", {
            type: "error",
          });
          reject();
        });
    });
  };

  const setUp = async () => {
    try {
      await Promise.all([getOvertimes()]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setUp();
    return () => {};
  }, []);

  useEffect(() => {
    const success = overtimes.filter((el) => el.status === "Approved").length;
    const pending = overtimes.filter((el) => el.status === "Pending").length;
    const reject = overtimes.filter((el) => el.status === "Declined").length;
    const sent = overtimes.filter(
      (el) => el.status === "Sent for Approval"
    ).length;
    const paid = overtimes.filter((el) => el.status === "Paid").length;
    setStatsOvertime({
      employee: overtimes.length,
      success,
      pending,
      reject,
      sent,
      paid,
    });
  }, [overtimes]);

  const deleteOvertime = () => {
    window.$("#delete_overtime").modal("toggle");
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendOvertime}/delete/${overtimeIdToDelete}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const removedDeleted = overtimes.filter(
            (overtime) => overtime._id !== overtimeIdToDelete
          );
          setOvertimes(removedDeleted);
          toast("Deleted", {
            type: "success",
          });
        } else {
          throw Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to delete", {
          type: "error",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getOvertimes();
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [filter]);

  const changeStatus = ({ overtimeId, status }) => {
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendOvertime}/change-status`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ overtimeId, status }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const updatedOvertimes = overtimes.map((el) => {
            if (el._id === overtimeId) {
              return { ...el, status };
            }
            return el;
          });
          setOvertimes(updatedOvertimes);
          toast("Status changed", {
            type: "success",
          });
          status === "Approved" && setShowForApproval(true);
        } else {
          throw Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to change status", {
          type: "error",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <UserTopbar />
          <UserSidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-8 col-6">
                  <h4 className="page-title">Allowance Application</h4>
                </div>
                <div className="col-sm-4 col-6 text-right m-b-30">
                  <button
                    className="btn add-btn"
                    onClick={() => setShowAddOvertimeModal(true)}
                  >
                    <i className="fa fa-plus"></i> Apply
                  </button>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Overtime Statistics -->*/}
              <section className="stats stats-3">
                <div className="stat">
                  <div className="icon icon-3">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.employee}</p>
                    <span className="dashboard-links">Total</span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon icon-1">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.success}</p>
                    <span className="dashboard-links">Approved Request </span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.pending}</p>
                    <span className="dashboard-links">Pending Request</span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon icon-3">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.sent}</p>
                    <span className="dashboard-links">Sent For Approval</span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon icon-1">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.paid}</p>
                    <span className="dashboard-links">Paid</span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon icon-2">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.reject}</p>
                    <span className="dashboard-links">Rejected Reject</span>
                  </div>
                </div>
              </section>
              {/*<!-- /Overtime Statistics -->*/}
              <hr />
              {/*<!-- Search Filter -->*/}
              <div className="row filter-row mb-3">
                {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      onChange={(e) => setSearchInputs(e.target.value)}
                    />
                    <label className="focus-label">Employee Name</label>
                  </div>
                </div> */}

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <Form.Control
                      as="select"
                      value={filter.status}
                      onChange={(e) =>
                        setFilter({ ...filter, status: e.target.value })
                      }
                    >
                      <option value=""> -- Select -- </option>
                      <option value="Pending"> Pending </option>
                      <option value="Approved"> Approved </option>
                      <option value="Declined"> Declined </option>
                      <option value="Sent for Approval">
                        Sent for Approval
                      </option>
                      <option value="Paid"> Paid </option>
                    </Form.Control>
                    <label className="focus-label">Overtime Status</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <DatePicker
                        value={filter.startDate}
                        dateFormat="DD MMM, YYYY"
                        onChange={(date) => {
                          setFilter({
                            ...filter,
                            startDate: date,
                          });
                        }}
                        closeOnSelect={true}
                        timeFormat={false}
                      />
                    </div>
                    <label className="focus-label">From</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <DatePicker
                        value={filter.endDate}
                        dateFormat="DD MMM, YYYY"
                        onChange={(date) => {
                          setFilter({
                            ...filter,
                            endDate: date,
                          });
                        }}
                        closeOnSelect={true}
                        timeFormat={false}
                      />
                    </div>
                    <label className="focus-label">To</label>
                  </div>
                </div>

                <div className="">
                  <button
                    onClick={() => {
                      setFilter({
                        endDate: "",
                        startDate: "",
                        status: "",
                      });
                    }}
                    className="btn  btn-secondary"
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/*<!-- /Search Filter -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr className="table-dark">
                          <th>Employee</th>
                          <th>Overtime</th>
                          <th className="text-center">From</th>
                          <th className="text-center">To</th>
                          <th>Days</th>
                          <th>Job Done</th>
                          <th>Amount</th>
                          <th className="text-center">Status</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {overtimes?.length
                          ? overtimes.map((overtime) => {
                              const { employee } = overtime;
                              return (
                                <>
                                  <tr className="table-primary">
                                    <td>
                                      <h2 className="table-avatar blue-link">
                                        <Link
                                          to={`profile/${employee?._id}`}
                                          className="avatar"
                                        >
                                          <img
                                            alt=""
                                            src="assets/img/profiles/avatar-02.jpg"
                                          />
                                        </Link>
                                        <Link to={`profile/${employee?._id}`}>
                                          {employee?.personalInfo.name.first +
                                            " " +
                                            employee?.personalInfo.name.last}
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>{overtime?.allowance?.name}</td>

                                    <td></td>
                                    <td></td>
                                    <td>
                                      {overtime.hours > 1
                                        ? overtime.hours + " days"
                                        : overtime.hours + " day"}
                                    </td>
                                    <td></td>
                                    <td>
                                      {currency(overtime?.total, {
                                        symbol: "",
                                      }).format()}
                                    </td>
                                    <td className="text-center">
                                      {overtime.status === "Paid" ? (
                                        <p className="btn btn-white btn-sm btn-rounded">
                                          <span className="fa fa-dot-circle-o text-success"></span>{" "}
                                          Paid
                                        </p>
                                      ) : (
                                        <Dropdown className="action-label">
                                          <Dropdown.Toggle
                                            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                            variant="btn-white"
                                          >
                                            {overtime.status ===
                                            "Sent for Approval" ? (
                                              <i className="fa fa-dot-circle-o text-purple"></i>
                                            ) : overtime.status ===
                                              "Pending" ? (
                                              <i className="fa fa-dot-circle-o text-info"></i>
                                            ) : overtime.status ===
                                              "Approved" ? (
                                              <i className="fa fa-dot-circle-o text-success"></i>
                                            ) : (
                                              <i className="fa fa-dot-circle-o text-danger"></i>
                                            )}
                                            {overtime.status}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu
                                            popperConfig={{
                                              strategy: "fixed",
                                            }}
                                            renderOnMount
                                            className="dropdown-with-icons"
                                          >
                                            {overtime.status === "Pending" &&
                                              overtime.status !==
                                                "Sent for Approval" && (
                                                <Dropdown.Item
                                                  as="button"
                                                  onClick={() => {
                                                    setSelected({
                                                      ...overtime,
                                                      newStatus:
                                                        "Sent for Approval",
                                                    });
                                                    setShowForApproval(true);
                                                  }}
                                                >
                                                  <i className="fa fa-dot-circle-o text-purple"></i>
                                                  Send for Approval
                                                </Dropdown.Item>
                                              )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {overtime.status === "Pending" ? (
                                        <div className="dropdown dropdown-action">
                                          <a
                                            href="javaScript:void(0);"
                                            className="action-icon dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="material-icons">
                                              more_vert
                                            </i>
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-right">
                                            <a
                                              className="dropdown-item"
                                              href="javaScript:void(0);"
                                              data-toggle="modal"
                                              data-target="#delete_overtime"
                                              onClick={() => {
                                                setOvertimeIdToDelete(
                                                  overtime._id
                                                );
                                              }}
                                            >
                                              <i className="fa fa-trash-o m-r-5"></i>{" "}
                                              Delete
                                            </a>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                  {overtime?.details?.map((el) => (
                                    <tr className="table-light">
                                      <td></td>
                                      <td>{el?.name}</td>
                                      <td>
                                        {moment(el?.overtimeDate).format(
                                          "DD MMM, YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {el?.overtimeDateTo
                                          ? moment(el?.overtimeDateTo).format(
                                              "DD MMM, YYYY"
                                            )
                                          : moment(el?.overtimeDate).format(
                                              "DD MMM, YYYY"
                                            )}
                                      </td>
                                      <td>
                                        {el?.days
                                          ? el?.days > 1
                                            ? el?.days + " days"
                                            : 1 + " day"
                                          : 1 + " day"}
                                      </td>
                                      <td>{el?.description}</td>
                                      <td>
                                        {currency(el?.amount, {
                                          symbol: "",
                                        }).format()}
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td></td>
                                  </tr>
                                </>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>

          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
      {/*<!-- Delete Overtime Modal -->*/}
      <div
        className="modal custom-modal fade"
        id="delete_overtime"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Overtime</h3>
                <p>Are you sure want to Delete this?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <a
                      href="javascript:void(0);"
                      className="btn btn-danger continue-btn"
                      onClick={() => deleteOvertime()}
                    >
                      Delete
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="javascript:void(0);"
                      data-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- /Delete Overtime Modal -->*/}

      {showAddOvertimeModal && (
        <ApplyUserAllowanceModal
          allowance={allowance}
          setShowAddOvertimeModal={setShowAddOvertimeModal}
          refetch={() => setUp()}
          setSelected={setSelected}
          openApproval={setShowForApproval}
        />
      )}

      <SendForApprovalModal
        show={showForApproval}
        onHide={() => setShowForApproval(false)}
        selected={selected}
        changeStatus={changeStatus}
      />

      <ModalLoader show={loading} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    // User: state.api.User,
    deductionSettings: state.deductionSettings.deductions,
    bonusSettings: state.bonusSettings.bonus,
    allowance: state.allowanceReducer.allowances,
    benefit: state.BenefitReducer.benefits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAllowance);
