import React, { Fragment } from "react";
import { URLS } from "../urls";
import moment from "moment";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { Modal } from "react-bootstrap";

export default class SendForApprovalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      Amount: 0,
      userId: "",
      staff: "",
      departments: [],
      users: [],
      comment: "",
      appliedDate: "",
      title: "",
      description: "",
      employee: "",
      details: [],
      newStatus: "",
      overtimeId: "",
    };
  }
  componentWillReceiveProps(props) {
    this.getDepartments();
    this.setState({
      Amount: props?.selected?.total,
      employee: `${
        props?.selected?.employee?.personalInfo?.name?.first
          ? props?.selected?.employee?.personalInfo?.name?.first
          : ""
      } ${
        props?.selected?.employee?.personalInfo?.name?.middle
          ? props?.selected?.employee?.personalInfo?.name?.middle
          : ""
      } ${
        props?.selected?.employee?.personalInfo?.name?.last
          ? props?.selected?.employee?.personalInfo?.name?.last
          : ""
      }`,
      newStatus: props?.selected?.newStatus,
      details: props?.selected?.details,
      overtimeId: props?.selected?._id,
    });
    // console.log(props.selected);
  }

  componentDidMount() {
    if (process.env.REACT_APP_HAS_INVEX !== "true") {
      return alert("Please activate accountant module to use Loan");
    }
  }

  getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();

        throw new Error(response.message);
      }

      const { data } = await response.json();

      this.setState({
        departments: data?.departments,
      });
    }
  };

  getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    this.setState({
      users: data?.users,
    });
  };

  onChangeInputs = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const {
      title,
      description,
      comment,
      Amount,
      details,
      staff,
      employee,
      overtimeId,
      newStatus,
    } = this.state;

    if (Object.entries(employee).length < 1) {
      return toast.error("Please select an employee", {
        position: "top-right",
      });
    }

    const requsion = {
      title,
      description,
      type: "Allowance",
      amount: Amount,
      requestto: staff,
      status: "Pending",
      regdate: moment(new Date()).format("YYYY-MM-DD"),
      requestby: staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({ employee, details, id: overtimeId }),
    };

    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(requsion)) {
      formData.append(key, value);
    }
    // console.log(requsion);
    const token = "dummy token";

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetch(`${URLS.mainSiteBackend}/requisition/create`, {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      }).then((res) => {
        if (res.ok) {
          res.json().then((result) => {
            // console.log(result);
            const payload = {
              overtimeId,
              status: newStatus,
            };
            this.props?.changeStatus && this.props.changeStatus({ ...payload });
          });
          this.setState({
            isWaiting: false,
            staff: "",
            departments: [],
            users: [],
            comment: "",
            details: [],
            newStatus: "",
            overtimeId: "",
            title: "",
            description: "",
            Amount: 0,
            userId: "",
            employee: "",
          });
          this.setState({ buttonText: "Submit", isWaiting: false });
          this.props.onHide();
        } else {
          res.json().then((responseJson) => {
            console.log(responseJson);
            toast("Error occurred, Check the form again.", {
              type: "error",
              position: "top-right",
            });
            this.setState({ buttonText: "Submit", isWaiting: false });
          });
        }
      });
    } else {
      return alert("Please activate accountant module to use Loan");
    }
  };

  render() {
    const {
      departments,
      users,
      Amount,
      staff,
      buttonText,
      title,
      description,
      comment,
      isWaiting,
      employee,
    } = this.state;

    return (
      <Modal
        className="modal custom-modal fade"
        show={this.props.show}
        centered
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send For Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <form onSubmit={this.submit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Employee <span className="text-danger">*</span> :
                  </Form.Label>

                  <Form.Control
                    name="employee"
                    placeholder="Enter a employee"
                    value={employee}
                    required
                    disabled
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Title <span className="text-danger">*</span> :
                  </Form.Label>
                  <Form.Control
                    name="title"
                    placeholder="Enter a title"
                    value={title}
                    onChange={this.onChangeInputs}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Description <span className="text-danger">*</span> :
                  </Form.Label>
                  <label className="border p-0 rounded pb-2 message-area d-block">
                    <Form.Control
                      name="description"
                      value={description}
                      onChange={this.onChangeInputs}
                      as="textarea"
                      className="w-100 border-0 p-3"
                      placeholder="Enter your detail description"
                      rows={4}
                      required
                    />
                  </label>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    Loan Amount <span className="text-danger">*</span> :
                  </Form.Label>

                  <CurrencyInput
                    className="form-control"
                    value={Amount}
                    allowNegativeValue={false}
                    disabled
                  />
                </Form.Group>

                <div className="d-flex align-items-center my-4">
                  <h4 className="pr-2">Send Request To</h4>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                <div className="row gap-3">
                  <Form.Group className="col mb-3">
                    <Form.Label>
                      Department <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        this.getUsersInDepartments(e.target.value)
                      }
                      required
                    >
                      <option value="">Select Recipient's Department</option>
                      {departments &&
                        departments.map((el, index) => (
                          <option key={index} value={el.Department}>
                            {el.Department}
                          </option>
                        ))}
                    </select>
                  </Form.Group>

                  {users.length > 0 && (
                    <Form.Group className="col mb-3">
                      <Form.Label>
                        Recipient <span className="text-danger">*</span> :
                      </Form.Label>
                      <select
                        name="staff"
                        className="form-control"
                        value={staff}
                        onChange={this.onChangeInputs}
                        required
                      >
                        <option value="">Select Recipient </option>
                        {users.map((el, index) => (
                          <Fragment key={index}>
                            <option value={el.Staff_ID}>{el.Name}</option>
                          </Fragment>
                        ))}
                      </select>
                    </Form.Group>
                  )}
                </div>

                <Form.Group className="mb-3">
                  <Form.Label>Comment :</Form.Label>
                  <label className="border p-0 rounded pb-2 message-area d-block">
                    <Form.Control
                      name="comment"
                      value={comment}
                      onChange={this.onChangeInputs}
                      as="textarea"
                      className="w-100 border-0 p-3"
                      placeholder="Enter a comment"
                      rows={4}
                    />
                  </label>
                </Form.Group>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={isWaiting}
                  >
                    {buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
