import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import currency from "currency.js";
// import moment from "moment";

import { URLS } from "../urls";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import ApplyLoanModal from "./ApplyLoanModal";
import LoanDetails from "./LoanDetails";
import LoanHistory from "./LoanHistory";
import { SummaryChartIconUp, SummaryCloseIcon } from "../icons";
import RsDateRangePicker from "../RsDateRangePicker";
import { branch_id } from "../../utils";
import { EditTermsModal } from "./EditTerms";
const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
class Loan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedLeaves: [],
      openLoanDetails: false,
      requestid: "",
      openLoanHistory: false,
      staff: "",
      stats: {},
      startDate: "",
      endDate: "",
      status: "",
      btnSummryActive: false,
      showTerm: false,
      selectedLoan: {},
    };
  }

  componentDidMount() {
    const token = "dummy token";

    this.sortLoan("", "", "");

    fetch(`${URLS.backendLoan}/allTotals/${branch_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("result => ", data);
        this.setState({
          stats: data,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, status } = this.state;
    if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
      this.sortLoan("", startDate, endDate);
    }

    if (prevState.status !== status) {
      this.sortLoan(status, "", "");
    }
  }

  showDateInFormat = (date) => {
    date = date.split("/");
    return (
      ("0" + date[0]).slice(-2) + " " + allMonths[date[1] - 1] + " " + date[2]
    );
  };

  updateTerms = (loan) => {
    this.setState({
      appliedLeaves: loan,
    });
  };

  sortLoan = (sta, startDate, endDate) => {
    const token = "dummy token";

    fetch(
      `${URLS.backendLoan}/by-branch/${branch_id}?stat=${sta}&startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log("result => ", result);
        this.setState({
          appliedLeaves: result.loan,
        });
      });
  };

  changeByStatus = (sta) => {
    this.setState({
      status: sta,
    });
  };

  clearDateRange = (event, picker) => {
    this.setState({
      startDate: "",
      endDate: "",
    });

    picker.element.val("");
  };

  filterByDateRange = (event, picker) => {
    this.setState({
      startDate: picker.startDate.format("DD/MM/yyyy"),
      endDate: picker.endDate.format("DD/MM/yyyy"),
    });
    picker.element.val(
      picker.startDate.format("DD/MM/yyyy") +
        " - " +
        picker.endDate.format("DD/MM/yyyy")
    );
  };

  render() {
    const {
      stats,
      appliedLeaves,
      loggedEmployee,
      openLoanDetails,
      requestid,
      openLoanHistory,
      staff,
      btnSummryActive,
      showTerm,
      selectedLoan,
    } = this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="d-flex justify-content-between align-item-baseline mb-4 mt-2">
                <div className="d-flex align-item-baseline">
                  <h4 className="page-title">Loan</h4>
                </div>

                <div className="d-flex align-item-baseline flex-wrap gap-3">
                  <div className="mb-2">
                    <RsDateRangePicker
                      // placement="bottomStart"
                      onClean={(event, picker) =>
                        this.clearDateRange(event, picker)
                      }
                      onOk={(event, picker) =>
                        this.filterByDateRange(event, picker)
                      }
                    />
                  </div>

                  <div className="mx-3">
                    <select
                      className="form-control"
                      onChange={(e) => this.changeByStatus(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="Approved">Approved</option>
                      <option value="Pending">Pending</option>
                      <option value="Disapproved">Disapproved</option>
                    </select>
                  </div>

                  <div className="">
                    <button
                      className="btn add-btn"
                      data-toggle="modal"
                      data-target="#add_leave"
                    >
                      <i className="fa fa-plus"></i> Apply Loan
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        this.setState({
                          btnSummryActive: !btnSummryActive,
                        })
                      }
                      className="btn summaryBtn mx-3"
                    >
                      Show Summary
                    </button>
                  </div>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Leave Statistics -->*/}
              <section className="stats">
                <div
                  className="stat p-cursor"
                  onClick={() => this.changeByStatus("")}
                >
                  <div className="icon icon-3">
                    <i class="fa fa-money"></i>
                  </div>
                  <div className="details">
                    <p>{appliedLeaves.length}</p>
                    <span className="dashboard-links">Total Loan </span>
                  </div>
                </div>
                <div
                  className="stat p-cursor"
                  onClick={() => this.changeByStatus("Approved")}
                >
                  <div className="icon icon-1">
                    <i class="fa fa-money"></i>
                  </div>
                  <div className="details">
                    <p>
                      {
                        appliedLeaves.filter((d) => d.status === "Approved")
                          .length
                      }
                    </p>
                    <span className="dashboard-links">Approved Loan </span>
                  </div>
                </div>
                <div
                  className="stat p-cursor"
                  onClick={() => this.changeByStatus("Pending")}
                >
                  <div className="icon icon-4">
                    <i class="fa fa-money"></i>
                  </div>
                  <div className="details">
                    <p>
                      {
                        appliedLeaves.filter((d) => d.status === "Pending")
                          .length
                      }
                    </p>
                    <span className="dashboard-links">Pending Loan </span>
                  </div>
                </div>
                <div
                  className="stat p-cursor"
                  onClick={() => this.changeByStatus("Disapproved")}
                >
                  <div className="icon icon-2">
                    <i class="fa fa-money"></i>
                  </div>
                  <div className="details">
                    <p>
                      {
                        appliedLeaves.filter((d) => d.status === "Disapproved")
                          .length
                      }
                    </p>
                    <span className="dashboard-links">Disapproved Loan </span>
                  </div>
                </div>
              </section>
              {/*<!-- /Leave Statistics -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Name</th>
                          <th>Title</th>
                          <th>Amount</th>
                          <th>RePaid</th>
                          <th>Balance</th>
                          <th>Deduct Amount</th>
                          <th>Status</th>
                          <th>Payment Status</th>
                          <th className="text-center">Term</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {appliedLeaves?.length
                          ? appliedLeaves?.map((loan, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{`${
                                    loan?.userID?.personalInfo?.name?.first
                                      ? loan?.userID?.personalInfo?.name?.first
                                      : ""
                                  } ${
                                    loan?.userID?.personalInfo?.name?.middle
                                      ? loan?.userID?.personalInfo?.name?.middle
                                      : ""
                                  } ${
                                    loan?.userID?.personalInfo?.name?.last
                                      ? loan?.userID?.personalInfo?.name?.last
                                      : ""
                                  }`}</td>
                                  <td>{loan?.Title}</td>
                                  <td>
                                    {currency(loan?.Amount, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                  <td>
                                    {loan?.paid
                                      ? currency(loan?.paid, {
                                          symbol: "",
                                        }).format()
                                      : 0}
                                  </td>
                                  <td>
                                    {!loan?.paid
                                      ? currency(loan?.Amount, {
                                          symbol: "",
                                        }).format()
                                      : loan?.balance
                                      ? currency(loan?.balance, {
                                          symbol: "",
                                        }).format()
                                      : 0}
                                  </td>
                                  <td>
                                    {loan?.payments?.length > 0
                                      ? currency(loan?.payments[0], {
                                          symbol: "",
                                        }).format()
                                      : 0}
                                  </td>
                                  <td
                                    className={`fw-bold ${
                                      loan.status === "Approved"
                                        ? "text-success"
                                        : loan.status === "Disapproved"
                                        ? "text-danger"
                                        : "text-pending"
                                    }`}
                                  >
                                    {loan.status}
                                  </td>
                                  <td
                                    className={`text-center fw-bold ${
                                      loan?.paymentsStatus === "Paid"
                                        ? "text-success"
                                        : "text-primary"
                                    }`}
                                  >
                                    {loan.paymentsStatus}
                                  </td>
                                  <td>
                                    {loan?.paymentsStatus === "Paid" ? (
                                      <div className="dropdown action-label p-cursor">
                                        <div
                                          className="btn btn-white btn-sm btn-rounded dropdown-toggle px-2"
                                          // data-toggle="dropdown"
                                          // aria-expanded="false"
                                        >
                                          <i className="fa fa-dot-circle-o text-success mx-2 text-center"></i>
                                          {/* { loan?.loanmotion === "Pause"
                                            ? "Pause"
                                            : loan.Term
                                            ? loan.Term
                                            : "Pending"} */}

                                          {loan?.motions &&
                                          loan?.loanmotion !== "Resume"
                                            ? loan?.loanmotion
                                            : loan?.Term
                                            ? loan?.Term
                                            : "Pending"}
                                        </div>
                                        {/* <div className="dropdown-menu dropdown-menu-right">
                                          {loan.Term !== "Monthly" && (
                                            <div
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#Monthly"
                                              onClick={() =>
                                                this.updateTerms(
                                                  "Monthly",
                                                  loan.requestid,
                                                  loan?.balance
                                                    ? loan?.balance
                                                    : loan?.Amount
                                                )
                                              }
                                            >
                                              <i className="fa fa-dot-circle-o m-r-5 text-info text-info"></i>
                                              Monthly
                                            </div>
                                          )}
                                          {loan.Term !== "Quaterly" && (
                                            <div
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#Quaterly"
                                              onClick={() =>
                                                this.updateTerms(
                                                  "Quaterly",
                                                  loan.requestid,
                                                  loan?.balance
                                                    ? loan?.balance
                                                    : loan?.Amount
                                                )
                                              }
                                            >
                                              <i className="fa fa-dot-circle-o m-r-5 text-info"></i>{" "}
                                              Quaterly
                                            </div>
                                          )}
                                          {loan.Term !== "Bi-Annual" && (
                                            <div
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#Bi-Annually"
                                              onClick={() =>
                                                this.updateTerms(
                                                  "Bi-Annual",
                                                  loan.requestid,
                                                  loan?.balance
                                                    ? loan?.balance
                                                    : loan?.Amount
                                                )
                                              }
                                            >
                                              <i className="fa fa-dot-circle-o m-r-5 text-info"></i>{" "}
                                              Bi-Annual
                                            </div>
                                          )}
                                          {loan.Term !== "Annual" && (
                                            <div
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#Annually"
                                              onClick={() =>
                                                this.updateTerms(
                                                  "Annual",
                                                  loan.requestid,
                                                  loan?.balance
                                                    ? loan?.balance
                                                    : loan?.Amount
                                                )
                                              }
                                            >
                                              <i className="fa fa-dot-circle-o m-r-5 text-info"></i>{" "}
                                              Annual
                                            </div>
                                          )}

                                          {loan?.loanmotion !== "Pause" && (
                                            <div
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#Pause"
                                              onClick={() =>
                                                this.updateTerms(
                                                  "Pause",
                                                  loan.requestid
                                                )
                                              }
                                            >
                                              <i className="fa fa-dot-circle-o m-r-5 text-info"></i>{" "}
                                              Pause
                                            </div>
                                          )}

                                          {loan?.loanmotion !== "Resume" &&
                                            loan?.loanmotion === "Pause" && (
                                              <div
                                                className="dropdown-item"
                                                data-toggle="modal"
                                                data-target="#Resume"
                                                onClick={() =>
                                                  this.updateTerms(
                                                    "Resume",
                                                    loan.requestid
                                                  )
                                                }
                                              >
                                                <i className="fa fa-dot-circle-o m-r-5 text-info"></i>{" "}
                                                Resume
                                              </div>
                                            )}
                                        </div> */}
                                      </div>
                                    ) : (
                                      loan.status === "Pending" && "Pending"
                                    )}
                                  </td>
                                  <td>
                                    {this.showDateInFormat(loan?.applyDate)}
                                  </td>

                                  <td>
                                    <div className="dropdown dropdown-action p-cursor">
                                      <div
                                        className="action-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="material-icons">
                                          more_vert
                                        </i>
                                      </div>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <div
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#edit_leave"
                                          onClick={() =>
                                            this.setState({
                                              openLoanDetails: true,
                                              requestid: loan?.requestid,
                                              staff: loan?.staff,
                                            })
                                          }
                                        >
                                          <i className="fa fa-rss m-r-5"></i>{" "}
                                          View Approval Progress
                                        </div>
                                        <div
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#delete_leave"
                                          onClick={() =>
                                            this.setState({
                                              openLoanHistory: true,
                                              requestid: loan?.requestid,
                                            })
                                          }
                                        >
                                          <i className="fa fa-history m-r-5"></i>{" "}
                                          View Repayment History
                                        </div>

                                        {loan?.paymentsStatus === "Paid" &&
                                          loan?.status !== "Completed" && (
                                            <div
                                              className="dropdown-item"
                                              onClick={() =>
                                                this.setState({
                                                  showTerm: true,
                                                  selectedLoan: loan,
                                                })
                                              }
                                            >
                                              <i className="fa fa-edit m-r-5"></i>{" "}
                                              Edit Term
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="row justify-content-between mt-4">
                  <div className="col">
                    <div className="my-3">
                      <div className="d-flex gap-3 align-items-center mb-4">
                        <h4 className="m-0">Loan Summary</h4>{" "}
                        <hr className="flex-grow-1 m-0" />
                        {btnSummryActive && (
                          <button
                            onClick={() =>
                              this.setState({
                                btnSummryActive: !btnSummryActive,
                              })
                            }
                            className="btn p-0"
                          >
                            <SummaryCloseIcon />
                          </button>
                        )}
                      </div>

                      <div
                        className="gridCont mb-3 three-col-grid"
                        style={{ gridTemplateRows: "unset" }}
                      >
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftBlue">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(stats?.TotalAmount, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Loan Amount</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(stats?.AmountPaid, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Amount Paid</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftOrange">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(stats?.TotalAmount, {
                                symbol: "",
                              })
                                .subtract(stats?.AmountPaid)
                                .format()}
                            </p>
                            <p className="gridChld2">
                              Total Withstanding Amount
                            </p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Leave Modal -->*/}
            <ApplyLoanModal employee={loggedEmployee} />
            {/*<!-- /Add Leave Modal -->*/}

            {/*<!-- Edit Leave Modal -->*/}
            {/* <EditLeaveModal leaveTypes={leaveTypes} editLeave={selectedLeave} /> */}
            {/*<!-- /Edit Leave Modal -->*/}

            {showTerm && (
              <EditTermsModal
                show={showTerm}
                handleClose={() => this.setState({ showTerm: false })}
                selectedLoan={selectedLoan}
                refreshterm={this.updateTerms}
              />
            )}

            {/*<!-- Delete Leave Modal -->*/}
            {/* <DeleteLeaveModal
              // removeLeaveFormAppliedLeavesList={
              //   this.removeLeaveFormAppliedLeavesList
              // }
              deleteLeave={selectedLeave}
            /> */}
            {/*<!-- /Delete Leave Modal -->*/}

            {openLoanDetails && (
              <LoanDetails
                requestid={requestid}
                show={openLoanDetails}
                staff={staff}
                onHide={() =>
                  this.setState({
                    openLoanDetails: false,
                  })
                }
              />
            )}
            {requestid && (
              <LoanHistory
                requestid={requestid}
                show={openLoanHistory}
                onHide={() =>
                  this.setState({
                    openLoanHistory: false,
                  })
                }
              />
            )}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Loan));
