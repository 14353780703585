import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Datepicker from "react-datetime";
import moment from "moment";
import Select from "react-select";
import currency from "currency.js";
import CurrencyInput from "react-currency-input-field";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import ModalLoadear from "../ModalLoader";
import { branch_id } from "../../utils";

import "../../../src/assets/css/ga-styles.css";
import { Form } from "react-bootstrap";
import { fetchActionsUtil } from "../../utils";

const initailFormData = {
  employeeId: "",
  jobGradeFrom: "",
  jobGradeTo: "",
  effectiveDate: new Date(),
  jobGradeFromText: "",
  departmentId: "",
  branch_id,
  daysWorkedInPreviousJobGrade: 0,
  employeeIdFromCompany: "",
  oldsalaryTotal: 0,
  employee_id: "",
};

function EditEmployeeJobGrade(props) {
  const date = new Date();
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });
  const [salary, setSalary] = useState({
    basic: 0,
    housing: 0,
    medical: 0,
    transport: 0,
    utility: 0,
    dressing: 0,
    entertainment: 0,
  });
  const [employees, setEmployees] = useState([]);
  const [mapEmployees, setMapEmployees] = useState([]);
  const [jobGradeHistory, setJobGradeHistory] = useState(initailFormData);
  const [jobGrades, setJobGrade] = useState([]);
  const [jobGradeHistorys, setJobGradeHistorys] = useState([]);
  const [jobGradeHistoryIdToDelete, setJobGradeHistoryIdToDelete] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [filterParams, setFilterParams] = useState({
    employeeId: "",
    employeeIdFromCompany: "",
    department: "",
    startDate: "",
    endDate: "",
  });
  const [salarySetting, setSalarySetting] = useState({});
  const [totalGrade, setTotalGrade] = useState(0);
  const [totaljobGrades, setTptalJobGrade] = useState([]);

  const setUp = async () => {
    setLoading(true);
    try {
      const resolvedPromises = await Promise.all([
        getJobGrades(),
        getJobGradeHistorys(),
        getEmployees(),
        getDepartments(),
        getSalaryByBranch(branch_id),
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUp();
    return () => {};
  }, []);

  const getSalaryByBranch = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = "dummy token";
        const data = await fetchActionsUtil(
          `${URLS.backendsalarySetting}/get/${id}`,
          "GET",
          token
        );
        if (data?.jobGrades?.length > 0) {
          const {
            basic,
            housing,
            medical,
            transport,
            utility,
            dressing,
            entertainment,
          } = data?.jobGrades[0];

          setSalarySetting({
            basic,
            housing,
            medical,
            transport,
            utility,
            dressing,
            entertainment,
          });
        }
        resolve();
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  };

  const updateSalaryInputs = (value) => {
    const grade = {};
    Object.entries({
      ...salarySetting,
    }).forEach((el) => {
      if (el[1] > 0) {
        const money = (parseFloat(el[1]) / 100) * parseFloat(value);
        grade[`${el[0]}`] = money;
      }
    });
    setSalary({ ...grade });
  };

  const onSelectedGrade = (item) => {
    const slay = totaljobGrades.find((el) => el.id === item).total;
    updateSalaryInputs(slay);
    setTotalGrade(slay);
  };

  const getDepartments = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await fetch(`${URLS.backendDepartments}/get`, {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          response = await response.json();
          throw new Error(response.message);
        }

        let { departments } = await response.json();
        departments = departments.map((el) => ({
          ...el,
          label: el.name,
          value: el._id,
        }));
        setDepartments(departments);
        resolve();
      } catch (err) {
        console.log(err);
        toast("Unable to get Department", {
          type: "error",
        });
        reject();
      }
    });
  };

  const getJobGradeHistorys = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      const {
        employeeId,
        employeeIdFromCompany,
        department,
        startDate,
        endDate,
      } = filterParams;

      fetch(
        `${URLS.backendJobGradeHistory}/get-job-grade-history/${branch_id}?employeeId=${employeeId}&employeeIdFromCompany=${employeeIdFromCompany}&department=${department}&startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          setJobGradeHistorys(result.jobGradeHistorys);
          resolve();
        })
        .catch((err) => {
          toast(
            "Unable to get Job Grade History, please refresh your browser",
            {
              type: "error",
            }
          );
          reject();
        });
    });
  };

  const getJobGrades = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setJobGrade([...result.jobGrades]);
          const jobsTotals = result?.jobGrades?.map((el) => {
            const tol = Object.values(el?.salary).reduce(
              (p, c) => parseFloat(p) + parseFloat(c),
              0
            );
            return {
              id: el?._id,
              total: tol,
            };
          });
          setTptalJobGrade(jobsTotals);
          resolve();
        })
        .catch((err) => {
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
          reject();
        });
    });
  };

  const getEmployees = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const date = new Date();

          const emp = result.employees.map((em) => ({
            ...em,
            label: `${
              em.personalInfo.name.first ? em.personalInfo.name.first : ""
            } ${
              em.personalInfo.name.middle ? em.personalInfo.name.middle : ""
            } ${em.personalInfo.name.last ? em.personalInfo.name.last : ""}`,
            value: em.userId,
            oldsalaryTotal: em?.jobInfo?.usergrade
              ? Object.values(em?.jobInfo?.usergrade?.salary).reduce(
                  (a, b) => a + b,
                  0
                )
              : 0,
          }));

          setEmployees(result.employees);
          setMapEmployees(emp);
          setJobGradeHistory({
            ...jobGradeHistory,
            jobGradeTo: result.employees[0].jobInfo?.grade?._id,
            effectiveDate: date,
          });
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  const saveJobGradeHistory = (e) => {
    e.preventDefault(e);

    if (!jobGradeHistory.jobGradeFromText)
      return alert("Add job grade before you update");

    setLoading(true);
    const grade = {};
    Object.entries(salary).forEach((el) => {
      grade[`${el[0]}`] = parseFloat(el[1]);
    });
    const jobGrade = {
      branch_id,
      salary: grade,
      totalGrade: parseFloat(totalGrade),
      userID: jobGradeHistory.employee_id,
      singleGrade: jobGradeHistory.jobGradeTo,
    };
    jobGradeHistory.jobGradeDetails = jobGrade;
    jobGradeHistory.salary = grade;

    const token = "dummy token";
    fetch(`${URLS.backendJobGradeHistory}/save-job-grade-history`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ jobGradeHistory }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          // console.log(result.jobGradeHistory);
          setJobGradeHistorys([...jobGradeHistorys, result.jobGradeHistory]);
          resetForm();
          window.$("#add_jobGradeHistory").modal("toggle");
          toast("Success", {
            type: "success",
          });
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to save jobGrade", {
          type: "error",
        });
      });
  };

  const editJobGradeHistory = (e) => {
    e.preventDefault(e);
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendJobGradeHistory}/edit-job-grade-history`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ jobGradeHistory }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          window.$("#edit_jobGradeHistory").modal("toggle");
          setJobGradeHistorys(
            jobGradeHistorys.map((el) => ({
              ...el,
              ...(result.jobGradeHistory._id === el._id
                ? result.jobGradeHistory
                : {}),
            }))
          );
          resetForm();
          toast("Saved", {
            type: "success",
          });
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to save changes", {
          type: "error",
        });
      });
  };

  const deleteJobGradeHistory = () => {
    window.$("#delete_jobGradeHistory").modal("toggle");
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendJobGradeHistory}/delete-job-grade-history`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ jobGradeHistoryId: jobGradeHistoryIdToDelete }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const removedDeleted = jobGradeHistorys.filter(
            (jobGradeHistory) =>
              jobGradeHistory._id !== jobGradeHistoryIdToDelete
          );
          setJobGradeHistorys(removedDeleted);
          toast("JobGradeHistory deleted", {
            type: "success",
          });
        } else {
          throw Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to delete jobGradeHistory", {
          type: "error",
        });
        setLoading(false);
      });
  };
  const resetForm = () => setJobGradeHistory(initailFormData);

  const search = async (e) => {
    e.preventDefault(e);
    try {
      setLoading(true);
      await getJobGradeHistorys();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const calculatePercentage = (value) => {
    setTotalGrade(value);
    updateSalaryInputs(value);
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Job Grade History</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className="page-title">Employee</h4> */}
              </div>
              <div className="col-auto text-right float-right ml-auto m-b-30">
                <button
                  className="btn add-btn"
                  data-toggle="modal"
                  data-target="#add_jobGradeHistory"
                  onClick={() => resetForm()}
                >
                  <i className="fa fa-plus"></i>Change Job Grade
                </button>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- /Page Title -->*/}

            <label className="">Effective Date </label>
            <div className="col-md-4 mb-3 px-0  d-flex   align-items-center">
              <div>
                <Datepicker
                  dateFormat="DD MMM, YYYY"
                  inputProps={{
                    placeholder: "DD MMM, YYYY",
                    readOnly: true,
                  }}
                  value={filterParams.startDate}
                  onChange={(date) => {
                    setFilterParams({
                      ...filterParams,
                      startDate: date,
                    });
                  }}
                  closeOnSelect={true}
                  timeFormat={false}
                />
              </div>
              <div className="mx-2">{" - "}</div>
              <div>
                <Datepicker
                  dateFormat="DD MMM, YYYY"
                  inputProps={{
                    placeholder: "DD MMM, YYYY",
                    readOnly: true,
                  }}
                  value={filterParams.endDate}
                  onChange={(date) => {
                    setFilterParams({
                      ...filterParams,
                      endDate: date,
                    });
                  }}
                  closeOnSelect={true}
                  timeFormat={false}
                />
              </div>
            </div>
            <Form
              className="row filter-row align-items-end"
              onSubmit={(e) => search(e)}
            >
              <div className="col-sm-6 col-md-3">
                <div className="form-group ">
                  <label className="">Employee ID</label>
                  <input
                    type="text"
                    className="form-control floating"
                    value={filterParams.employeeIdFromCompany}
                    onChange={(e) =>
                      setFilterParams({
                        ...filterParams,
                        employeeIdFromCompany: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="form-group ">
                  <label className="">Employee Name</label>
                  {mapEmployees && (
                    <Select
                      key={filterParams.employeeId}
                      closeMenuOnSelect={true}
                      isSearchable
                      required
                      classNamePrefix="form-control"
                      value={mapEmployees?.find(
                        (el) => el.value === filterParams.employeeId
                      )}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          employeeId: selected?.value || "",
                        });
                      }}
                      options={mapEmployees}
                      isClearable
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="form-group ">
                  <label className="focus-label">Department</label>
                  <Select
                    key={filterParams.department}
                    closeMenuOnSelect={true}
                    isSearchable
                    required
                    placeholder="Select Department"
                    classNamePrefix="form-control"
                    value={departments?.find(
                      (el) => el.value === filterParams.department
                    )}
                    onChange={(selected) => {
                      setFilterParams({
                        ...filterParams,
                        department: selected?.value || "",
                      });
                    }}
                    options={departments}
                    isClearable
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <button className="btn add-btn btn-block  mb-3">
                  {" "}
                  Search{" "}
                </button>
              </div>
            </Form>

            {/*  <hr />
            <div className="d-flex  mb-3">
              <div className="form-inline">
                <label>Month</label>
                <select
                  className="form-control mx-2"
                  value={payrollDate.payrollMonth}
                  onChange={(e) =>
                    setPayrollDate({
                      payrollMonth: e.target.value,
                      payrollYear: payrollDate.payrollYear,
                    })
                  }
                >
                  {[...Array(12)].map((e, index) => (
                    <option key={index}>{index + 1}</option>
                  ))}
                </select>
              </div>
              <div className="form-inline ml-3">
                <label>Year</label>
                <input
                  type="number"
                  style={{ width: "100px" }}
                  className="form-control mx-2"
                  value={payrollDate.payrollYear}
                  onChange={(e) =>
                    setPayrollDate({
                      payrollMonth: payrollDate.payrollMonth,
                      payrollYear: e.target.value,
                    })
                  }
                />
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  {/*<!-- JobGradeHistory Table -->*/}
                  {
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Employee </th>
                          <th>Department</th>
                          <th>Old Job Grade </th>

                          <th>Days worked in previous Job Grade</th>
                          <th>New Job Grade </th>
                          <th>Effective Date </th>
                          <th>Date Created</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobGradeHistorys?.map((jobGradeHistory, index) => {
                          const { employee, promotedFrom, promotedTo } =
                            jobGradeHistory;
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <h2 className="table-avatar blue-link">
                                  <Link
                                    to={`profile/${employee?._id}`}
                                    className="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="assets/img/profiles/avatar-02.jpg"
                                    />
                                  </Link>
                                  <Link to={`profile/${employee?._id}`}>
                                    {employee?.personalInfo.name.first +
                                      " " +
                                      employee?.personalInfo.name.last}
                                  </Link>
                                </h2>
                              </td>
                              <td>{jobGradeHistory?.department?.name}</td>
                              <td>{jobGradeHistory?.jobGradeFromData?.name}</td>

                              <td>
                                {jobGradeHistory?.daysWorkedInPreviousJobGrade}
                              </td>
                              <td>{jobGradeHistory?.jobGradeToData?.name}</td>
                              <td>
                                {moment(jobGradeHistory?.effectiveDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </td>
                              <td>
                                {moment(jobGradeHistory?.createdAt).format(
                                  "DD MMM, YYYY"
                                )}
                              </td>
                              <td className="text-right">
                                <div className="dropdown dropdown-action p-cursor">
                                  <div
                                    className="action-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="material-icons">more_vert</i>
                                  </div>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <div
                                      className="dropdown-item"
                                      data-toggle="modal"
                                      data-target="#edit_jobGradeHistory"
                                      onClick={() => {
                                        setJobGradeHistory({
                                          ...initailFormData,
                                          ...jobGradeHistory,
                                          effectiveDate: moment(
                                            jobGradeHistory?.effectiveDate
                                          ).format("DD MMM, YYYY"),
                                          employeeId: employee.userId,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </div>
                                    <div
                                      className="dropdown-item"
                                      data-toggle="modal"
                                      data-target="#delete_jobGradeHistory"
                                      onClick={() => {
                                        setJobGradeHistoryIdToDelete(
                                          jobGradeHistory._id
                                        );
                                      }}
                                    >
                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                  {/*<!-- /JobGradeHistory Table --> */}
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Add JobGradeHistory Modal -->*/}
          <div
            id="add_jobGradeHistory"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Change Job Grade </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetForm()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => saveJobGradeHistory(e)}>
                    <div className="form-group">
                      <label>
                        Employee <span className="text-danger">*</span>
                      </label>

                      {mapEmployees && (
                        <Select
                          closeMenuOnSelect={true}
                          isSearchable
                          required
                          classNamePrefix="form-control"
                          value={mapEmployees?.find(
                            (el) => el.value === jobGradeHistory?.employeeId
                          )}
                          onChange={(selected) => {
                            if (!selected.value) return;

                            setJobGradeHistory({
                              ...jobGradeHistory,
                              employeeId: selected.value,
                              employeeIdFromCompany:
                                selected.jobInfo?.employeeId,
                              jobGradeFrom: selected?.jobInfo?.grade?._id,
                              jobGradeFromText: selected?.jobInfo?.grade?.name,
                              departmentId: selected?.jobInfo?.department?._id,
                              oldsalaryTotal: selected?.oldsalaryTotal,
                              employee_id: selected?._id,
                            });
                          }}
                          options={mapEmployees}
                        />
                      )}
                    </div>

                    <div className="row">
                      <div className="form-group col-6">
                        <label>
                          Old Job Grade <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control d-none"
                          required
                          value={jobGradeHistory.jobGradeFrom || ""}
                          readOnly
                          name="jobGradeFrom"
                        />
                        <input
                          className="form-control"
                          value={jobGradeHistory.jobGradeFromText || ""}
                          disabled
                          name="jobGradeFromText"
                          required
                        />
                        <input
                          className="form-control d-none"
                          value={jobGradeHistory.employeeIdFromCompany || ""}
                          readOnly
                          name="employeeIdFromCompany"
                        />
                      </div>
                      <div className="col-6">
                        <label>
                          Old Job Salary <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          value={currency(jobGradeHistory.oldsalaryTotal || 0, {
                            symbol: "",
                          }).format()}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>
                        Days worked as {jobGradeHistory.jobGradeFromText || ""}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        required
                        value={jobGradeHistory.daysWorkedInPreviousJobGrade}
                        onChange={(e) => {
                          setJobGradeHistory({
                            ...jobGradeHistory,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        name="daysWorkedInPreviousJobGrade"
                        type="number"
                      />
                    </div>

                    <div className="row">
                      <div className="form-group col-6">
                        <label>
                          New Job Grade <span className="text-danger">*</span>
                        </label>
                        {jobGrades && (
                          <select
                            className="form-control"
                            name="jobGradeTo"
                            required
                            value={jobGradeHistory.jobGradeTo}
                            onChange={(e) => {
                              onSelectedGrade(e.target.value);
                              setJobGradeHistory({
                                ...jobGradeHistory,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select Job Grade</option>
                            {jobGrades.map((grade, index) => {
                              return (
                                <option key={index} value={grade._id}>
                                  {grade.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                      <div className="col-6">
                        <label>
                          New Job Salary <span className="text-danger">*</span>
                        </label>
                        <CurrencyInput
                          className="form-control"
                          value={totalGrade}
                          allowNegativeValue={false}
                          onValueChange={(value, name) =>
                            calculatePercentage(value)
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>
                        Effective Date <span className="text-danger">*</span>
                      </label>
                      <div classname="datePicker position-absolute">
                        <Datepicker
                          dateFormat="DD MMM, YYYY"
                          value={jobGradeHistory.effectiveDate}
                          onChange={(date) => {
                            setJobGradeHistory({
                              ...jobGradeHistory,
                              effectiveDate: date,
                            });
                          }}
                          closeOnSelect={true}
                          timeFormat={false}
                        />
                      </div>
                    </div>

                    <div className="submit-section">
                      <button className="btn add-btn submit-btn">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Add JobGradeHistory Modal -->*/}

          {/*<!-- Edit JobGradeHistory Modal -->*/}
          <div
            id="edit_jobGradeHistory"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Job Grade</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetForm()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => editJobGradeHistory(e)}>
                    <div className="form-group">
                      <label>
                        Employee <span className="text-danger">*</span>
                      </label>

                      {mapEmployees && (
                        <Select
                          key={jobGradeHistory?.employeeId}
                          closeMenuOnSelect={true}
                          isSearchable
                          required
                          classNamePrefix="form-control"
                          value={mapEmployees?.find(
                            (el) => el.value === jobGradeHistory?.employeeId
                          )}
                          onChange={(selected) => {
                            if (!selected.value) return;
                            setJobGradeHistory({
                              ...jobGradeHistory,
                              employeeId: selected.value,
                              employeeIdFromCompany:
                                selected.jobInfo?.employeeId,
                              jobGradeFrom: selected?.jobInfo?.grade?._id,
                              jobGradeFromText: selected?.jobInfo?.grade?.name,
                              departmentId: selected?.jobInfo?.department?._id,
                            });
                          }}
                          isDisabled
                          options={mapEmployees}
                        />
                      )}
                    </div>

                    <div className="form-group">
                      <label>
                        Old Job Grade <span className="text-danger">*</span>
                      </label>
                      {jobGrades && (
                        <select
                          className="form-control"
                          name="jobGradeFrom"
                          required
                          value={jobGradeHistory.jobGradeFrom}
                          onChange={(e) => {
                            setJobGradeHistory({
                              ...jobGradeHistory,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select Job Grade</option>
                          {jobGrades.map((grade, index) => {
                            return (
                              <option key={index} value={grade._id}>
                                {grade.name}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>

                    <div className="form-group">
                      <label>
                        Days worked as {jobGradeHistory.jobGradeFromText || ""}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        required
                        value={jobGradeHistory.daysWorkedInPreviousJobGrade}
                        onChange={(e) => {
                          setJobGradeHistory({
                            ...jobGradeHistory,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        name="daysWorkedInPreviousJobGrade"
                        type="number"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        New Job Grade <span className="text-danger">*</span>
                      </label>
                      {jobGrades && (
                        <select
                          className="form-control"
                          name="jobGradeTo"
                          required
                          value={jobGradeHistory.jobGradeTo}
                          onChange={(e) => {
                            setJobGradeHistory({
                              ...jobGradeHistory,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select Job Grade</option>
                          {jobGrades.map((grade, index) => {
                            return (
                              <option key={index} value={grade._id}>
                                {grade.name}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Effective Date <span className="text-danger">*</span>
                      </label>
                      <div classname="datePicker position-absolute">
                        <Datepicker
                          dateFormat="DD MMM, YYYY"
                          value={jobGradeHistory.effectiveDate}
                          onChange={(date) => {
                            setJobGradeHistory({
                              ...jobGradeHistory,
                              effectiveDate: date,
                            });
                          }}
                          closeOnSelect={true}
                          timeFormat={false}
                        />
                      </div>
                    </div>

                    <div className="submit-section">
                      <button className="btn add-btn submit-btn">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Edit JobGradeHistory Modal -->*/}

          {/*<!-- Delete JobGradeHistory Modal -->*/}
          <div
            className="modal custom-modal fade"
            id="delete_jobGradeHistory"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete JobGradeHistory</h3>
                    <p>Are you sure want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action p-cursor">
                    <div className="row">
                      <div className="col-6">
                        <div
                          className="btn btn-danger continue-btn"
                          onClick={() => deleteJobGradeHistory()}
                        >
                          Delete
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          data-dismiss="modal"
                          className="btn btn-primary cancel-btn"
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Delete JobGradeHistory Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoadear show={loading} />
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditEmployeeJobGrade));
